import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Navbar from "./Navbar";
import { logOut } from "./../../modules/auth/store/auth.actions";
import { callInProcess } from "store/core/core.actions";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logOut,
      callInProcess,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    restaurant: auth.restaurant,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
