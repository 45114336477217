import { combineEpics } from "redux-most";
import toastEpics from "./toast/toast.epics";
import coreEpics from "./core/core.epics";
import authEpics from "../modules/auth/store/auth.epics";
import orderEpics from "../modules/order/store/order.epics";
import menuEpics from "../modules/menu/store/menu.epics";
import tableEpics from "../modules/table/store/table.epics";
import dealsEpics from "../modules/deals/store/deals.epics";

const rootEpic = combineEpics([
  toastEpics,
  coreEpics,
  authEpics,
  orderEpics,
  menuEpics,
  tableEpics,
  dealsEpics,
]);

export default rootEpic;
