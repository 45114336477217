import { combineEpics, select } from "redux-most";
import { empty } from "most";
import { SHOW_TOAST } from "./toast.actions";
import { toast } from "react-toastify";

const toastEpic = ($actions) =>
  $actions.thru(select(SHOW_TOAST)).flatMap((action) => {
    const { message, type } = action.payload;
    if (message) {
      toast[type](message);
    }
    return empty();
  });

export default combineEpics([toastEpic]);
