import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import OrderDetailsModal from "./OrderDetailsModal";
import { callInProcess } from "store/core/core.actions";
import {
  getOrderDetails,
  getOrdersByStatus,
} from "../../modules/order/store/order.actions";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOrderDetails,
      getOrdersByStatus,
      callInProcess,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  const { order, core } = state;
  return {
    orderDetails: order.orderDetails,
    isLoading: core.callInProcess,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsModal);
