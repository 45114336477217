import React from "react";
import "./RegistrationSteps.css";
import PropTypes from "prop-types";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
// import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CustomButton from "../CustomButton";
import StepButton from "@material-ui/core/StepButton";
import Logo from "assets/img/logo.png";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: "inline-block",
    },
    instructions: {
      fontSize: "1rem",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 1.5,
    },
  })
);

const VerticalStepper = ({
  activeStep,
  handleStep,
  steps,
  getStepContent,
  handleBack,
  handleNext,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root + " container"}>
      <div className="stepper">
        <div className="stepper-logo-section">
          <img src={Logo} />
        </div>
        <Stepper
          nonLinear
          activeStep={activeStep}
          className="stepper-navigation-section"
          orientation="vertical"
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton
                onClick={handleStep(index)}
                completed={activeStep > index}
                disabled={activeStep < index}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </div>
      <div className="stepper-content">
        <div className={classes.instructions}>{getStepContent(activeStep)}</div>
        <div className="buttons-section">
          {activeStep !== 0 ? (
            <div>
              <Button onClick={handleBack} className={classes.button}>
                Back
              </Button>
            </div>
          ) : (
            <div>
              {/* <Button onClick={handleNext} className={classes.button}>
                Skip For Now
              </Button> */}
            </div>
          )}
          <div>
            {activeStep !== 0 ? <div>{/* Skip For Now */}</div> : null}
            <CustomButton
              title={activeStep === 2 ? "Done" : "Next"}
              navigateTo={handleNext}
            />
          </div>
          {/* {activeStep !== steps.length &&
            (completed[activeStep] ? (
              <Typography variant="caption" className={classes.completed}>
                Step {activeStep + 1} already completed
              </Typography>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleComplete}
              >
                {completedSteps() === totalSteps() - 1
                  ? "Finish"
                  : "Complete Step"}
              </Button>
            ))} */}
        </div>
      </div>
    </div>
  );
};

VerticalStepper.propTypes = {
  activeStep: PropTypes.number,
  handleStep: PropTypes.func,
  steps: PropTypes.any,
  getStepContent: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};

export default VerticalStepper;
