export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILURE = "GET_CATEGORIES_FAILURE";
export const GET_MENUS = "GET_MENUS";
export const GET_MENUS_SUCCESS = "GET_MENUS_SUCCESS";
export const GET_MENUS_FAILURE = "GET_MENUS_FAILURE";
export const GET_MENU_DETAILS = "GET_MENU_DETAILS";
export const GET_MENU_DETAILS_SUCCESS = "GET_MENU_DETAILS_SUCCESS";
export const GET_MENU_DETAILS_FAILURE = "GET_MENU_DETAILS_FAILURE";
export const GET_TABS = "GET_TABS";
export const GET_TABS_SUCCESS = "GET_TABS_SUCCESS";
export const GET_TABS_FAILURE = "GET_TABS_FAILURE";
export const CREATE_TAB = "CREATE_TAB";
export const CREATE_TAB_SUCCESS = "CREATE_TAB_SUCCESS";
export const CREATE_TAB_FAILURE = "CREATE_TAB_FAILURE";
export const CREATE_MENU = "CREATE_MENU";
export const CREATE_MENU_SUCCESS = "CREATE_MENU_SUCCESS";
export const CREATE_MENU_FAILURE = "CREATE_MENU_FAILURE";
export const EDIT_MENU = "EDIT_MENU";
export const EDIT_MENU_SUCCESS = "EDIT_MENU_SUCCESS";
export const EDIT_MENU_FAILURE = "EDIT_MENU_FAILURE";
export const DELETE_TAB = "DELETE_TAB";
export const DELETE_MENU = "DELETE_MENU";

export const getMenus = ({ type, params }) => ({
  type: GET_MENUS,
  payload: { type, params },
});

export const getMenusSuccess = ({ menus, type }) => ({
  type: GET_MENUS_SUCCESS,
  payload: { menus, type },
});

export const getCategories = ({ params }) => ({
  type: GET_CATEGORIES,
  payload: { params },
});

export const getCategoriesSuccess = ({ categories }) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: { categories },
});

export const getMenuDetails = ({ id }) => ({
  type: GET_MENU_DETAILS,
  payload: { id },
});

export const getMenuDetailsSuccess = ({ menuDetails }) => ({
  type: GET_MENU_DETAILS_SUCCESS,
  payload: { menuDetails },
});

export const getTabs = ({ type, params }) => ({
  type: GET_TABS,
  payload: { type, params },
});

export const getTabsSuccess = ({ tabs }) => ({
  type: GET_TABS_SUCCESS,
  payload: { tabs },
});

export const createTab = (params) => ({
  type: CREATE_TAB,
  payload: { params },
});

export const createTabSuccess = ({ tab }) => ({
  type: CREATE_TAB_SUCCESS,
  payload: { tab },
});

export const createMenu = (params) => ({
  type: CREATE_MENU,
  payload: { params },
});

export const createMenuSuccess = ({ menu }) => ({
  type: CREATE_MENU_SUCCESS,
  payload: { menu },
});

export const editMenu = (params, id) => ({
  type: EDIT_MENU,
  payload: { params, id },
});

export const editMenuSuccess = ({ menu }) => ({
  type: EDIT_MENU_SUCCESS,
  payload: { menu },
});

export const deleteTab = (id) => ({
  type: DELETE_TAB,
  payload: { id },
});

export const deleteMenu = (id) => ({
  type: DELETE_MENU,
  payload: { id },
});
