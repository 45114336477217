import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Tables from "./Tables";
import { getTables } from "./../store/table.actions";
import { callInProcess } from "store/core/core.actions";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTables,
      callInProcess,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  const { table } = state;
  return {
    tables: table.tables,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tables);
