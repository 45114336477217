import forge from "mappersmith";
import { apiBaseUrl } from "../../../constants/api-baseUrl";
import { ServerErrorMiddleware } from "../../middleware/server-error-middleware";
import { AccessTokenMiddleware } from "../../middleware/access-token-middleware";
import EncodeJson from "mappersmith/middleware/encode-json";
import { ServerResponseMiddleware } from "../../middleware/server-response-middleware";

const ServerErrors = ServerErrorMiddleware();
const ServerResponse = ServerResponseMiddleware();
const AccesTokenMiddleware = AccessTokenMiddleware();
const client = forge({
  host: apiBaseUrl,
  middleware: [ServerErrors, ServerResponse, AccesTokenMiddleware, EncodeJson],
  resources: {
    Order: {
      getOrders: {
        path: "/order",
        method: "get",
      },
      getRecentOrders: {
        path: "/order?recent=true",
        method: "get",
      },
      getOrderDetails: {
        path: "/order/{id}",
        method: "get",
        bodyAttr: "orderId",
      },
      getAnalytics: {
        path: "/order/analytics",
        method: "get",
        bodyAttr: "params",
      },
      getOrderPayment: {
        path: "/order/payment/{id}",
        method: "get",
        bodyAttr: "params",
      },
      getOrdersByStatus: {
        path: "/order/status/{id}",
        method: "put",
        bodyAttr: "params",
      },
    },
  },
});

export const orders = ({ type, params }) => {
  console.log(type);
  if (type === "recentOrders") {
    return client.Order.getRecentOrders(params);
  }
  return client.Order.getOrders(params);
};

export const orderDetails = ({ id }) => {
  console.log(id);
  return client.Order.getOrderDetails({ id });
};

export const getAnalytics = (params) => {
  console.log(params);
  return client.Order.getAnalytics(params);
};

export const getOrderPayment = ({ params, id }) => {
  console.log(id);
  return client.Order.getOrderPayment({ ...params, id });
};

export const getOrdersByStatus = ({ params, id }) => {
  return client.Order.getOrdersByStatus({ params, id });
};
