/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import "../Auth.css";
import Sliderlayout from "../Sliderlayout";
// import Socialbuttons from "../Socialbuttons";
import CustomButton from "../CustomButton";
import FormControl from "@material-ui/core/FormControl";
import { NavLink } from "react-router-dom";
import UserIcon from "assets/icons/user.png";
import LockIcon from "assets/icons/lock.png";
import TextField from "@material-ui/core/TextField";
import EyeCrossedIcon from "assets/icons/eye-crossed-icon.png";
import {
  checkFormValid,
  checkForRequiredField,
} from "../../../constants/validation";
import { useHistory } from "react-router-dom";
import Error from "../../../components/Error/Error";

const Login = ({ login, callInProcess }) => {
  const history = useHistory();
  useEffect(() => {
    window.appHistory = history;
  }, [history]);

  const [showPassword, setShowPassword] = useState(false);

  const formLabels = {
    email: "Email",
    password: "Password",
  };

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const [formErrorState, setFormErrorState] = useState({
    email: "",
    password: "",
  });

  const setFieldValues = (field, value) => {
    const errMessage = checkForRequiredField(field, value, formLabels);
    setFormErrorState((prev) => ({
      ...prev,
      [field]: errMessage,
    }));
    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const validateAndLogin = () => {
    if (!checkFormValid(form, formErrorState, setFormErrorState, formLabels)) {
      return;
    }
    callInProcess(true);
    login({ email: form.email, password: form.password });
  };

  return (
    <div className="container">
      <div className="slider">
        <Sliderlayout />
      </div>
      <div className="login">
        <div className="login-container">
          <div className="login-inner-container">
            <h2>Hey,</h2>
            <h2>Login Now</h2>
            <h4 className="login-inner-title">
              Don't have an account?
              <NavLink className="links" to={"/signup"}>
                Create Account
              </NavLink>
            </h4>
            <div>
              <FormControl variant="outlined" className="inputs">
                <TextField
                  id="outlined-secondary"
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Username or Email"
                  InputProps={{
                    startAdornment: (
                      <img src={UserIcon} className="login-icons" />
                    ),
                  }}
                  onChange={(value) =>
                    setFieldValues("email", value.target.value)
                  }
                />
                {formErrorState.email && <Error error={formErrorState.email} />}
              </FormControl>
              <FormControl variant="outlined" className="inputs">
                <TextField
                  id="outlined-adornment-password"
                  type={showPassword ? "type" : "password"}
                  variant="outlined"
                  size="small"
                  placeholder="Password"
                  InputProps={{
                    startAdornment: (
                      <img src={LockIcon} className="login-icons" />
                    ),
                    endAdornment: (
                      <img
                        src={EyeCrossedIcon}
                        className="eye-crossed-icon"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ),
                  }}
                  onChange={(value) =>
                    setFieldValues("password", value.target.value)
                  }
                />
                {formErrorState.password && (
                  <Error error={formErrorState.password} />
                )}
              </FormControl>
            </div>
            <p className="login-forgot-password">
              Forgot Password?
              <NavLink className="links" to={"/reset"}>
                Reset Now
              </NavLink>
            </p>
            <CustomButton
              title="Login"
              customStyles={{
                width: "153px",
                height: "60px",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: "20px",
                lineHeight: "23px",
              }}
              navigateTo={() => validateAndLogin()}
            />
          </div>
          {/* <Socialbuttons /> */}
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  callInProcess: PropTypes.func.isRequired,
};

export default Login;
