import { combineEpics, select } from "redux-most";
import * as most from "most";
import {
  GET_LANGUAGE,
  languageSuccess,
  callInProcess1,
  POST_TOKEN,
  postTokenSuccess,
} from "./core.actions";
import { showToast } from "store/toast/toast.actions";
import {
  getLanguagesService,
  postToken,
} from "../../core/services/core/core.service";

const getLanguagesEpic = ($actions) =>
  $actions.thru(select(GET_LANGUAGE)).flatMap(() => {
    return most
      .fromPromise(getLanguagesService())
      .flatMap((response) => {
        return most.from([
          callInProcess1(false),
          languageSuccess(response.items || []),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess1(false));
      });
  });

const postTokenEpic = ($actions) =>
  $actions.thru(select(POST_TOKEN)).flatMap((action) => {
    return most
      .fromPromise(postToken(action.payload))
      .flatMap(() => {
        return most.from([postTokenSuccess(action.payload)]);
      })
      .recoverWith(() => {
        return most.of(
          showToast({
            message: "Something went wrong! while creating token",
            type: "error",
          })
        );
      });
  });

export default combineEpics([getLanguagesEpic, postTokenEpic]);
