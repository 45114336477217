import React, { useEffect } from "react";
import "./ItemDetailsModal.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
// import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import { PropTypes } from "prop-types";
// import SelectBox from "components/SelectBox/SelectBox";
import CreateMenuModal from "views/CreateMenuModal";

const styles = (theme) => ({
  root: {
    margin: 0,
    borderBottom: "none",
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: "25px",
    top: "25px",
    padding: "0px",
    width: "16px",
    height: "16px",
    color: theme.palette.grey[500],
    "&:hover": {
      background: "transparent",
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose } = props;
  return (
    // <MuiDialogTitle disableTypography className={classes.root} {...other}>
    //   {onClose ? (
    //   ) : null}
    // </MuiDialogTitle>
    <IconButton
      aria-label="close"
      className={classes.closeButton}
      onClick={onClose}
    >
      <CloseIcon />
    </IconButton>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function ItemDetailsModal({
  selectedItemDetailsModal,
  setSelectedItemDetailsModal,
  showCreateMenuModal,
  setShowCreateMenuModal,
  categories = [],
}) {
  const [menuName, setMenuName] = React.useState("");

  useEffect(() => {
    console.log(categories);
  }, [categories]);

  useEffect(() => {
    const menu = categories.find(
      (category) => category.id === selectedItemDetailsModal?.subCatId
    );
    setMenuName(menu?.name);
  }, [selectedItemDetailsModal]);
  console.log(selectedItemDetailsModal);

  const useStyles = makeStyles(() => ({
    backDrop: {
      backdropFilter: "blur(3px)",
      backgroundColor: "rgba(50,50,30,0.1)",
    },
  }));

  const classes = useStyles();

  return (
    <Dialog
      onClose={() => setSelectedItemDetailsModal(null)}
      aria-labelledby="customized-dialog-title"
      open={selectedItemDetailsModal}
      fullWidth="md"
      maxWidth="md"
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => setSelectedItemDetailsModal(null)}
      ></DialogTitle>
      <DialogContent>
        <div className="item-details-main">
          <div className="item-details-left">
            <div className="item-details-img-div">
              <img
                className="item-details-img"
                src={selectedItemDetailsModal?.img}
              />
            </div>
            <div className="item-details-sub-main">
              <div className="item-details-RuskFlusk">
                <span className="item-details-heading">
                  {selectedItemDetailsModal?.name}
                </span>
              </div>
              <p className="item-details-p">
                {selectedItemDetailsModal?.description}
              </p>
              <div className="item-details-btn-div">
                <button
                  className="btn-pad-rad btn-edit"
                  onClick={() => [
                    setSelectedItemDetailsModal(null),
                    setShowCreateMenuModal(selectedItemDetailsModal),
                  ]}
                >
                  Edit
                </button>
                <div></div>
                {/* <SelectBox
                  menuList={["Success", "Reject", "Pending"]}
                  label="Status"
                  customStyle={{ margin: "0px 20px" }}
                /> */}
              </div>
            </div>
          </div>
          <div className="item-details-right">
            <div className="item-details-details">
              <div className="item-details-right-heading">Details</div>
              <div className="item-details-right-content">
                <div className="item-details-right-top">
                  <div className="item-details-right-top-label">
                    Order Amount:
                  </div>
                  <div className="greenCSS">
                    ${selectedItemDetailsModal?.price}
                  </div>
                </div>
                <div className="item-details-right-top">
                  <div className="item-details-right-top-label2">
                    Menu Category:
                  </div>
                  <div>
                    <FormControl
                      size="small"
                      variant="outlined"
                      className="create-menu-modal-input"
                    >
                      <span className="item-details-category">{menuName}</span>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div className="item-details-addOns">
              <span className="item-details-right-heading">Add Ons</span>
              <div className="item-details-right-content">
                {selectedItemDetailsModal
                  ? selectedItemDetailsModal?.addons?.map((addon, index) => (
                      <div className="addOn-main" key={index}>
                        <div className="greyCSS">Addon {index + 1}</div>
                        <div className="addOn-middle-div">
                          <div className="greenCSS">${addon.price}</div>
                          <div className="addOn-below-green">{addon.name}</div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      {showCreateMenuModal ? (
        <CreateMenuModal
          showCreateMenuModal={showCreateMenuModal}
          setShowCreateMenuModal={setShowCreateMenuModal}
        />
      ) : (
        <></>
      )}
    </Dialog>
  );
}
ItemDetailsModal.propTypes = {
  selectedItemDetailsModal: PropTypes.boolean,
  setSelectedItemDetailsModal: PropTypes.Func,
  showCreateMenuModal: PropTypes.any,
  setShowCreateMenuModal: PropTypes.func,
  categories: PropTypes.any,
};
