import React from "react";
import "./RegistrationSteps.css";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { setFieldValues } from "../../../constants/validation";
// import Error from "components/Error/Error";
import ImageUploading from "react-images-uploading";
import ImageUploding from "assets/img/Vector.png";
import CustomButton from "../CustomButton/index";
import Error from "../../../components/Error/Error";
// import { uploadFile } from "react-s3";
// import { config } from "constants/config-constant";
import { toast } from "react-toastify";
// import aws from "aws-sdk";
// import formidable from "formidable-serverless";
import axios from "axios";
import { apiBaseUrl } from "constants/api-baseUrl";

const maxNumber = 1;

// function DataURIToBlob(dataURI) {
//   const splitDataURI = dataURI.split(",");
//   const byteString =
//     splitDataURI[0].indexOf("base64") >= 0
//       ? atob(splitDataURI[1])
//       : decodeURI(splitDataURI[1]);
//   const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

//   const ia = new Uint8Array(byteString.length);
//   for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

//   return new Blob([ia], { type: mimeString });
// }

const RegistrationStep2 = ({
  formLabels,
  form,
  setForm,
  formErrorState,
  setFormErrorState,
  callInProcess,
}) => {
  const uploadImage = (field, imageList) => {
    console.log(imageList);
    setFieldValues(
      field,
      imageList[0],
      "",
      formLabels,
      setFormErrorState,
      form,
      setForm
    );
    setFormErrorState({
      ...formErrorState,
      [field]: "",
    });
  };
  const handleUpload = async (file, type) => {
    callInProcess(true);
    console.log("=======================================", file);

    // let img = DataURIToBlob(file);
    const formData = new FormData();
    formData.append("cvc", file, "image.jpg");

    let doc = await axios.post(`${apiBaseUrl}fileupload`, formData);

    console.log(
      "uploadMediaResponse-----------------------------------------======================",
      doc
    );
    uploadImage(type, [doc.data.url]);
    callInProcess(false);
    toast.success("Upload Successfully");
    // uploadFile(file, config)
    //   .then((data) => {
    //     console.log(data);
    //     uploadImage(type, [data.location]);
    //     callInProcess(false);
    //     toast.success("Upload Successfully");
    //   })
    //   .catch((err) => console.error(err));
  };

  return (
    <div className="content-container">
      <div className="App"></div>
      <h2>Setting Up Your Business</h2>
      <div className="profile-pic-section">
        <div className="display-image-section">
          <div className="image-uploading">
            {form?.logo?.data_url || form?.logo ? (
              <img
                src={form?.logo?.data_url || form?.logo}
                className="selected-image"
              />
            ) : (
              <img src={ImageUploding} />
            )}
          </div>
          <div className="image-uploading-input">
            <p>Upload Restaurant Logo</p>
            <TextField
              id="outlined-secondary"
              type="text"
              variant="outlined"
              size="small"
              className="input"
              placeholder="Restaurant Logo"
              value={form?.logo}
              disabled={true}
            />
            {formErrorState.logo && <Error error={formErrorState.logo} />}
            <ImageUploading
              onChange={(imageList) => handleUpload(imageList[0].file, "logo")}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({ onImageUpload }) => (
                <div>
                  <CustomButton
                    title="Upload"
                    navigateTo={() => onImageUpload()}
                  />
                </div>
              )}
            </ImageUploading>
          </div>
        </div>
        <div className="display-image-section">
          <div className="image-uploading-cover">
            {form.coverImg.data_url || form?.coverImg ? (
              <img
                src={form?.coverImg?.data_url || form?.coverImg}
                className="selected-image"
              />
            ) : (
              <img src={ImageUploding} />
            )}
          </div>
          <div className="image-uploading-input">
            <p>Upload Cover Image</p>
            <TextField
              id="outlined-secondary"
              type="text"
              variant="outlined"
              size="small"
              className="input"
              value={form?.coverImg}
              placeholder="Restaurant Cover Image"
              disabled={true}
            />
            {formErrorState.coverImg && (
              <Error error={formErrorState.coverImg} />
            )}
            <ImageUploading
              onChange={(imageList) =>
                handleUpload(imageList[0].file, "coverImg")
              }
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({ onImageUpload }) => (
                <div>
                  <CustomButton
                    title="Upload"
                    navigateTo={() => onImageUpload()}
                  />
                </div>
              )}
            </ImageUploading>
          </div>
        </div>
      </div>
    </div>
  );
};

RegistrationStep2.propTypes = {
  formLabels: PropTypes.object,
  form: PropTypes.object,
  setForm: PropTypes.func,
  formErrorState: PropTypes.object,
  setFormErrorState: PropTypes.func,
  callInProcess: PropTypes.func,
};
export default RegistrationStep2;
