import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CreateTableModal from "./CreateTableModal";
import {
  createTable,
  editTable,
} from "../../modules/table/store/table.actions";
import { callInProcess } from "store/core/core.actions";

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createTable,
      editTable,
      callInProcess,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateTableModal);
