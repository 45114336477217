import { combineEpics, select } from "redux-most";
import * as most from "most";
import {
  getMenusSuccess,
  GET_MENUS,
  getMenuDetailsSuccess,
  GET_MENU_DETAILS,
  GET_TABS,
  getTabsSuccess,
  CREATE_TAB,
  // createTabSuccess,
  CREATE_MENU,
  // createMenuSuccess,
  GET_CATEGORIES,
  getCategoriesSuccess,
  EDIT_MENU,
  DELETE_TAB,
  DELETE_MENU,
} from "./menu.actions";
import { callInProcess } from "store/core/core.actions";
import {
  menus,
  menuDetails,
  tabs,
  createTab,
} from "core/services/menu/menu.service";
import { createMenu } from "core/services/menu/menu.service";
import { getCategories } from "core/services/menu/menu.service";
import { getRestaurantById } from "modules/auth/store/auth.actions";
import { showToast } from "store/toast/toast.actions";
import { editMenu } from "core/services/menu/menu.service";
import { deleteTab } from "core/services/menu/menu.service";
import { deleteMenu } from "core/services/menu/menu.service";

const menusEpic = ($actions) =>
  $actions.thru(select(GET_MENUS)).flatMap((action) => {
    return most
      .fromPromise(menus(action.payload))
      .flatMap((response) => {
        console.log(response);
        return most.from([
          getMenusSuccess({
            type: action.payload.type,
            menus: response,
          }),
          callInProcess(false),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const getCategoriesEpic = ($actions) =>
  $actions.thru(select(GET_CATEGORIES)).flatMap((action) => {
    return most
      .fromPromise(getCategories(action.payload))
      .flatMap((response) => {
        console.log(
          "Response-------------------------------------------",
          response
        );
        return most.from([
          getCategoriesSuccess({
            categories: response,
          }),
          callInProcess(false),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const menuDetailsEpic = ($actions) =>
  $actions.thru(select(GET_MENU_DETAILS)).flatMap((action) => {
    return most
      .fromPromise(menuDetails(action.payload))
      .flatMap((response) => {
        console.log(response);
        return most.from([
          getMenuDetailsSuccess({
            menuDetails: response,
          }),
          callInProcess(false),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const tabsEpic = ($actions) =>
  $actions.thru(select(GET_TABS)).flatMap((action) => {
    return most
      .fromPromise(tabs(action.payload))
      .flatMap((response) => {
        console.log(response);
        return most.from([
          getTabsSuccess({
            tabs: response,
          }),
          callInProcess(false),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const tabEpic = ($actions) =>
  $actions.thru(select(CREATE_TAB)).flatMap((action) => {
    return most
      .fromPromise(createTab(action.payload))
      .flatMap((response) => {
        console.log(response);
        return most.from([
          getRestaurantById(),
          // createTabSuccess({
          //   tab: response,
          // }),
          callInProcess(false),
          showToast({
            message: "Create Tab Successfully",
            type: "success",
          }),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const createMenuEpic = ($actions) =>
  $actions.thru(select(CREATE_MENU)).flatMap((action) => {
    return most
      .fromPromise(createMenu(action.payload))
      .flatMap((response) => {
        console.log(response);
        return most.from([
          getRestaurantById(),
          // createMenuSuccess({
          //   menu: response,
          // }),
          callInProcess(false),
          showToast({
            message: "Create Menu Successfully",
            type: "success",
          }),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const editMenuEpic = ($actions) =>
  $actions.thru(select(EDIT_MENU)).flatMap((action) => {
    return most
      .fromPromise(
        editMenu({ params: action.payload.params, id: action.payload.id })
      )
      .flatMap((response) => {
        console.log(response);
        return most.from([
          getRestaurantById(),
          // createMenuSuccess({
          //   menu: response,
          // }),
          callInProcess(false),
          showToast({
            message: "Menu Updated",
            type: "success",
          }),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const deleteTabEpic = ($actions) =>
  $actions.thru(select(DELETE_TAB)).flatMap((action) => {
    return most
      .fromPromise(deleteTab(action.payload))
      .flatMap((response) => {
        console.log(response);
        return most.from([
          getRestaurantById(),
          // createMenuSuccess({
          //   menu: response,
          // }),
          callInProcess(false),
          showToast({
            message: "Deleted Tab Successfully",
            type: "success",
          }),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const deleteMenuEpic = ($actions) =>
  $actions.thru(select(DELETE_MENU)).flatMap((action) => {
    return most
      .fromPromise(deleteMenu(action.payload))
      .flatMap((response) => {
        console.log(response);
        return most.from([
          getRestaurantById(),
          // createMenuSuccess({
          //   menu: response,
          // }),
          callInProcess(false),
          showToast({
            message: "Deleted Menu Successfully",
            type: "success",
          }),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

export default combineEpics([
  menusEpic,
  menuDetailsEpic,
  tabsEpic,
  tabEpic,
  createMenuEpic,
  getCategoriesEpic,
  editMenuEpic,
  deleteTabEpic,
  deleteMenuEpic,
]);
