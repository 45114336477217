import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Navigation from "./Navigation";
import { appInitialized, postToken } from "./../store/core/core.actions";
import { identifyAuthUser } from "../modules/auth/store/auth.actions";
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      appInitialized,
      identifyAuthUser,
      postToken,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  const { core, auth } = state;
  return {
    initialLoading: core.initialLoading,
    isLoggedIn: auth.isLoggedIn,
    callInProcess: core.callInProcess,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
