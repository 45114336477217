import {
  CREATE_TABLE_SUCCESS,
  GET_TABLES_SUCCESS,
  GET_TABLE_BY_ID_SUCCESS,
} from "./table.actions";

const INITIAL_STATE = {
  tables: {
    data: [],
  },
  table: {},
};

const tableReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TABLES_SUCCESS:
      return {
        ...state,
        tables: action.payload.tables,
      };
    case CREATE_TABLE_SUCCESS:
      return {
        ...state,
        tables: {
          ...state.tables,
          data: [action.payload.table, ...state.tables.data],
        },
      };
    case GET_TABLE_BY_ID_SUCCESS:
      return {
        ...state,
        table: action.payload.table,
      };
    // case GET_MENU_DETAILS_SUCCESS:
    //   return {
    //     ...state,
    //     menuDetails: action.payload.menuDetails,
    //   };
    // case GET_TABS_SUCCESS:
    //   return {
    //     ...state,
    //     tabs: action.payload.tabs,
    //   };
    default:
      return state;
  }
};

export default tableReducer;
