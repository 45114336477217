import React from "react";
import "./TableCard.css";
import TableImage from "assets/img/table-image.png";
import { PropTypes } from "prop-types";
import DeleteIcon from "assets/icons/delete.png";
import EditIcon from "assets/icons/edit.png";
import ConfirmationModal from "views/ConfirmationModal";
// import { useHistory } from "react-router-dom";

export default function TableCard({
  tables,
  setShowCreateTableModal,
  deleteTable,
}) {
  // const history = useHistory();
  const pdfPage = (table) => {
    // history.push("/admin/pdfTable");
    window.open(`/pdf-table/${table.id}`, "_blank");
  };
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(
    null
  );

  const deleteSelectedTable = () => {
    if (showConfirmationModal) {
      deleteTable(showConfirmationModal.id);
      setShowConfirmationModal(null);
    }
  };
  return (
    <div className="table-container">
      {tables.map((table, index) => (
        <div className="card" key={index}>
          <div className="left" onClick={() => pdfPage(table)}>
            <img
              src={tables.img ? table.img : TableImage}
              width="40"
              height="30"
            />
          </div>
          <div className="right">
            <div className="card-top">
              <span>{table.capacity} Persons</span>
              <span className="table-dot"></span>
              <span className="booked">
                {table.isBooked === false ? "Available" : "Booked"}
              </span>
            </div>
            <h2 className="table-name">
              {table.name.length > 12
                ? table.name.slice(0, 12) + "..."
                : table.name}
            </h2>
            <div className="bpara">
              <span>Download QR</span>
              <div className="panini-menu-item-action-buttons">
                <img
                  src={EditIcon}
                  onClick={() => setShowCreateTableModal(table)}
                />
                <img
                  src={DeleteIcon}
                  onClick={() => setShowConfirmationModal(table)}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      {showConfirmationModal ? (
        <ConfirmationModal
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={setShowConfirmationModal}
          message="Are you sure to delete"
          submit={deleteSelectedTable}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
TableCard.propTypes = {
  tables: PropTypes.any.isRequired,
  deleteTable: PropTypes.func,
  setShowCreateTableModal: PropTypes.func,
  showQrModal: PropTypes.func,
};
