import React, { useState } from "react";
import { PropTypes } from "prop-types";
import "../Auth.css";
import "./Reset.css";
import Sliderlayout from "../Sliderlayout";
// import Socialbuttons from "../Socialbuttons";
import CustomButton from "../CustomButton";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import TextField from "@material-ui/core/TextField";
import { NavLink } from "react-router-dom";
import EyeCrossedIcon from "assets/icons/eye-crossed-icon.png";
import {
  checkFormValid,
  checkForRequiredField,
} from "../../../constants/validation";
import Error from "../../../components/Error/Error";

export default function Reset({ callInProcess, reset }) {
  const formLabels = {
    email: "Email",
  };

  const [form, setForm] = useState({
    email: "",
  });

  const [formErrorState, setFormErrorState] = useState({
    email: "",
  });

  const setFieldValues = (field, value) => {
    const errMessage = checkForRequiredField(field, value, formLabels);
    setFormErrorState((prev) => ({
      ...prev,
      [field]: errMessage,
    }));
    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const submit = () => {
    if (!checkFormValid(form, formErrorState, setFormErrorState, formLabels)) {
      return;
    }
    callInProcess(true);
    reset(form);
  };

  return (
    <div className="container">
      <div className="slider">
        <Sliderlayout />
      </div>
      <div className="login">
        <div className="login-container">
          <div className="login-inner-container">
            <h2>Forgot</h2>
            <h2>Password</h2>
            <h4 className="forgot-inner-title">
              We will send you instructions on email to change your password.
              <NavLink className="links" to={"/login"}>
                Login Now
              </NavLink>
            </h4>
            <div>
              <FormControl variant="outlined" className="inputs">
                <TextField
                  id="outlined-secondary"
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Email Address"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineIcon
                          fontSize="small"
                          className="email-icon"
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <img src={EyeCrossedIcon} className="eye-crossed-icon" />
                    ),
                  }}
                  onChange={(value) =>
                    setFieldValues("email", value.target.value)
                  }
                />
                {formErrorState.email && <Error error={formErrorState.email} />}
              </FormControl>
            </div>
            <CustomButton
              title="Send"
              customStyles={{
                width: "153px",
                height: "60px",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: "20px",
                lineHeight: "23px",
              }}
              navigateTo={() => submit()}
            />
          </div>
          {/* <Socialbuttons /> */}
        </div>
      </div>
    </div>
  );
}
Reset.propTypes = {
  callInProcess: PropTypes.Func,
  reset: PropTypes.Func,
};
