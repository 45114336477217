import React from "react";
import "./MenuCard.css";
import FoodItem from "assets/img/food-item.png";
import ItemDetailsModal from "views/ItemDetailsModal";
import { PropTypes } from "prop-types";
import ConfirmationModal from "views/ConfirmationModal";
// import CrossIcon from "assets/icons/cross-icon.png";
import DeleteIcon from "assets/icons/delete.png";
import EditIcon from "assets/icons/edit.png";

export default function MenuCard({
  menus = [],
  showCreateMenuModal,
  setShowCreateMenuModal,
  deleteMenu,
}) {
  const [
    selectedItemDetailsModal,
    setSelectedItemDetailsModal,
  ] = React.useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(
    null
  );
  console.log("menus --->>> ", menus);
  const deleteSelectedMenu = () => {
    if (showConfirmationModal) {
      deleteMenu(showConfirmationModal.id);
      setShowConfirmationModal(null);
    }
  };
  return (
    <div className="panini-card-container">
      {menus.map((menu) => (
        <>
          <div className="panini-card" key={menu.id}>
            <div className="panini-img-div">
              <img
                className="panini-img"
                src={menu.img ? menu.img : FoodItem}
                onClick={() => setSelectedItemDetailsModal(menu)}
              />
            </div>
            <div className="panini-txt-div">
              <span className="PC-small-font">
                ${menu.price}
                <div className="panini-menu-item-action-buttons">
                  <img
                    src={EditIcon}
                    onClick={() => setShowCreateMenuModal(menu)}
                  />
                  <img
                    src={DeleteIcon}
                    onClick={() => setShowConfirmationModal(menu)}
                  />
                </div>
              </span>
              <span className="PC-Heading">
                {menu.name.length > 22
                  ? menu.name.slice(0, 22) + "..."
                  : menu.name}
              </span>
              <span className="PC-desc" style={{ color: "grey" }}>
                {menu.description.length > 60
                  ? menu.description.slice(0, 60) + "..."
                  : menu.description}
              </span>
            </div>
            {/* <img
              src={CrossIcon}
              className="menu-delete-icon"
              onClick={() => setShowConfirmationModal(menu)}
            /> */}
          </div>
        </>
      ))}
      <ItemDetailsModal
        selectedItemDetailsModal={selectedItemDetailsModal}
        setSelectedItemDetailsModal={setSelectedItemDetailsModal}
        showCreateMenuModal={showCreateMenuModal}
        setShowCreateMenuModal={setShowCreateMenuModal}
      />
      {showConfirmationModal ? (
        <ConfirmationModal
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={setShowConfirmationModal}
          message="Are you sure to delete"
          submit={deleteSelectedMenu}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
MenuCard.propTypes = {
  selectedItemDetailsModal: PropTypes.boolean,
  setSelectedItemDetailsModal: PropTypes.func,
  showCreateMenuModal: PropTypes.any,
  setShowCreateMenuModal: PropTypes.func,
  menus: PropTypes.Array,
  deleteMenu: PropTypes.func,
};
