import React, { useState } from "react";
import "./CreateNewTabModal.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
// import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { PropTypes } from "prop-types";
import CustomButton from "modules/auth/CustomButton/CustomButton";

const styles = (theme) => ({
  root: {
    margin: 0,
    borderBottom: "none",
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: "25px",
    top: "25px",
    padding: "0px",
    width: "16px",
    height: "16px",
    color: theme.palette.grey[500],
    "&:hover": {
      background: "transparent",
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose } = props;
  return (
    // <MuiDialogTitle disableTypography className={classes.root} {...other}>
    //   {onClose ? (
    //   ) : null}
    // </MuiDialogTitle>
    <IconButton
      aria-label="close"
      className={classes.closeButton}
      onClick={onClose}
    >
      <CloseIcon />
    </IconButton>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CreateNewTabModal({
  showCreateNewTabModal,
  setShowCreateNewTabModal,
  createTab,
  callInProcess,
}) {
  const [tabName, setTabName] = useState("");

  const closeModal = () => {
    setShowCreateNewTabModal(false);
  };

  const createNewTab = () => {
    if (tabName) {
      callInProcess(true);
      createTab({ resId: 1, name: tabName });
      closeModal();
    }
  };

  const useStyles = makeStyles(() => ({
    backDrop: {
      backdropFilter: "blur(3px)",
      backgroundColor: "rgba(50,50,30,0.1)",
    },
  }));

  const classes = useStyles();

  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={showCreateNewTabModal}
      fullWidth="sm"
      maxWidth="sm"
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={closeModal}
      ></DialogTitle>
      <DialogContent>
        <div className="create-new-tab-container">
          <div className="create-new-tab-inner-container">
            <p className="create-new-tab-input-label">Create Tab</p>
            <FormControl
              size="small"
              variant="outlined"
              className="create-menu-modal-input"
            >
              <TextField
                id="outlined-secondary"
                type="text"
                variant="outlined"
                size="small"
                placeholder="Add new tab"
                value={tabName}
                onChange={(event) => setTabName(event.target.value)}
              />
            </FormControl>
          </div>
          <div className="creat-new-tab-action-buttons">
            <CustomButton
              title="Cancel"
              customStyles={{
                width: "98px",
                backgroundColor: "#ffffff",
                color: "#42526E",
                border: "1px solid rgba(66, 82, 110, 0.7)",
                marginRight: "20px",
              }}
              navigateTo={closeModal}
            />
            <CustomButton
              title="Create"
              customStyles={{ width: "131px" }}
              navigateTo={createNewTab}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
CreateNewTabModal.propTypes = {
  showCreateNewTabModal: PropTypes.boolean,
  setShowCreateNewTabModal: PropTypes.func,
  callInProcess: PropTypes.func.isRequired,
  createTab: PropTypes.func.isRequired,
};
