import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RestaurantInformation from "./RestaurantInformation";
import { editRestaurantInfo } from "../../modules/auth/store/auth.actions";
import { getRestaurantById } from "../../modules/auth/store/auth.actions";
import { callInProcess } from "store/core/core.actions";
import { getCategories } from "../../modules/menu/store/menu.actions";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editRestaurantInfo,
      getRestaurantById,
      callInProcess,
      getCategories,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  const { auth, menu } = state;
  return {
    restaurant: auth.restaurant,
    categories: menu.categories,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestaurantInformation);
