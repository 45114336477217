import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AccountSettings from "./AccountSettings";
import { updatePassword } from "../../modules/auth/store/auth.actions";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updatePassword,
    },
    dispatch
  );

const mapStateToProps = () => {};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
