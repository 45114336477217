export const GET_TABLES = "GET_TABLES";
export const GET_TABLES_SUCCESS = "GET_TABLES_SUCCESS";
export const GET_TABLES_FAILURE = "GET_TABLES_FAILURE";
export const CREATE_TABLE = "CREATE_TABLE";
export const CREATE_TABLE_SUCCESS = "CREATE_TABLE_SUCCESS";
export const CREATE_TABLE_FAILURE = "CREATE_TABLE_FAILURE";
export const GET_TABLE_BY_ID = "GET_TABLE_BY_ID";
export const GET_TABLE_BY_ID_SUCCESS = "GET_TABLE_BY_ID_SUCCESS";
export const EDIT_TABLE = "EDIT_TABLE";
export const EDIT_TABLE_SUCCESS = "EDIT_TABLE_SUCCESS";
export const DELETE_TABLE = "DELETE_TABLE";
export const DELETE_TABLE_SUCCESS = "DELETE_TABLE_SUCCESS";
// export const GET_MENU_DETAILS = "GET_MENU_DETAILS";
// export const GET_MENU_DETAILS_SUCCESS = "GET_MENU_DETAILS_SUCCESS";
// export const GET_MENU_DETAILS_FAILURE = "GET_MENU_DETAILS_FAILURE";
// export const GET_TABS = "GET_TABS";
// export const GET_TABS_SUCCESS = "GET_TABS_SUCCESS";
// export const GET_TABS_FAILURE = "GET_TABS_FAILURE";

export const getTables = ({ params }) => ({
  type: GET_TABLES,
  payload: { params },
});

export const getTablesSuccess = ({ tables }) => ({
  type: GET_TABLES_SUCCESS,
  payload: { tables },
});

export const getTableById = (id) => ({
  type: GET_TABLE_BY_ID,
  payload: { id },
});

export const getTableByIdSuccess = ({ table }) => ({
  type: GET_TABLE_BY_ID_SUCCESS,
  payload: { table },
});

export const createTable = (params) => ({
  type: CREATE_TABLE,
  payload: { params },
});

export const createTableSuccess = ({ tab }) => ({
  type: CREATE_TABLE_SUCCESS,
  payload: { tab },
});

export const editTable = ({ params }) => ({
  type: EDIT_TABLE,
  payload: { params },
});

export const editTableSuccess = ({ tables }) => ({
  type: EDIT_TABLE_SUCCESS,
  payload: { tables },
});

export const deleteTable = (id) => ({
  type: DELETE_TABLE,
  payload: { id },
});

export const deleteTableSuccess = (id) => ({
  type: DELETE_TABLE_SUCCESS,
  payload: { id },
});
// export const getMenuDetails = ({ id }) => ({
//   type: GET_MENU_DETAILS,
//   payload: { id },
// });

// export const getMenuDetailsSuccess = ({ menuDetails }) => ({
//   type: GET_MENU_DETAILS_SUCCESS,
//   payload: { menuDetails },
// });

// export const getTabs = ({ type, params }) => ({
//   type: GET_TABS,
//   payload: { type, params },
// });

// export const getTabsSuccess = ({ tabs }) => ({
//   type: GET_TABS_SUCCESS,
//   payload: { tabs },
// });
