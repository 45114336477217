import forge from "mappersmith";
import { apiBaseUrl } from "../../../constants/api-baseUrl";
import { ServerErrorMiddleware } from "../../middleware/server-error-middleware";
import { AccessTokenMiddleware } from "../../middleware/access-token-middleware";
import EncodeJson from "mappersmith/middleware/encode-json";
import { ServerResponseMiddleware } from "../../middleware/server-response-middleware";

const ServerErrors = ServerErrorMiddleware();
const ServerResponse = ServerResponseMiddleware();
const AccesTokenMiddleware = AccessTokenMiddleware();
const client = forge({
  host: apiBaseUrl,
  middleware: [ServerErrors, ServerResponse, AccesTokenMiddleware, EncodeJson],
  resources: {
    Table: {
      getTables: {
        path: "/table/res/{id}",
        method: "get",
        bodyAttr: "params",
      },
      createTable: {
        path: "/table",
        method: "post",
        bodyAttr: "tableData",
      },
      getTableById: {
        path: "/table/{id}",
        method: "get",
      },
      editTable: {
        path: "/table/{id}",
        method: "put",
        bodyAttr: "params",
      },
      deleteTable: {
        path: "/table/{id}",
        method: "delete",
      },
      // getMenuDetails: {
      //   path: "/item/{id}",
      //   method: "get",
      //   bodyAttr: "menuId",
      // },
      // getTabs: {
      //   path: "/tabs",
      //   method: "get",
      // },
      // createTab: {
      //   path: "/tabs",
      //   method: "post",
      //   bodyAttr: "params",
      // },
    },
  },
});

export const tables = ({ params, id = 0 }) => {
  console.log(params, id);
  return client.Table.getTables({ id, ...params });
};

export const createTable = (tableData) => {
  console.log(tableData);
  return client.Table.createTable({ tableData });
};

export const getTableById = (id) => {
  return client.Table.getTableById({ id });
};

export const editTable = ({ params, id = 0 }) => {
  return client.Table.editTable({ id, ...params });
};

export const deleteTable = (id) => {
  return client.Table.deleteTable({ id });
};

// export const menuDetails = ({ id }) => {
//   console.log(id);
//   return client.Menu.getMenuDetails({ id });
// };

// export const tabs = ({ type, params }) => {
//   console.log(type);
//   return client.Menu.getTabs(params);
// };

// export const createTab = ({ params }) => {
//   console.log(params);
//   return client.Menu.createTab({ params });
// };
