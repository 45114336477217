import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CreateMenuModal from "./CreateMenuModal";
import { createMenu, editMenu } from "../../modules/menu/store/menu.actions";
import { callInProcess } from "store/core/core.actions";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createMenu,
      editMenu,
      callInProcess,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  const { auth } = state;
  console.log(
    "DataFromMenu------------------------------------------------",
    auth
  );
  return {
    categories: auth.restaurant.subCategories,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateMenuModal);
