import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Login from "./../modules/auth/Login/index";
import Signup from "./../modules/auth/Signup/index";
import RegistrationSteps from "./../modules/auth/RegistrationSteps/index";
import { Redirect } from "react-router";
import Admin from "layouts/Admin.js";
import { PropTypes } from "prop-types";
import PdfTable from "views/PdfTable";
import Reset from "modules/auth/Reset/index";
import { onMessageListener, getToken } from "./../firebase";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Navigation = ({
  initialLoading,
  isLoggedIn,
  identifyAuthUser,
  callInProcess,
  postToken,
}) => {
  const classes = useStyles();
  const [token, setTokenFound] = useState(false);
  // const [userInfo, setUserInfo] = useState(0);
  useEffect(() => {
    identifyAuthUser({});
    // userDetails();
  }, []);
  // const userDetails = () => {
  //   if (isLoggedIn) {
  //     let user = localStorage.getItem("user");
  //     console.log(user);
  //     let JsonData = JSON.parse(user);
  //     setUserInfo(JsonData.resId);
  //     console.log(
  //       "UserDetailsInNavigation--------------------------------------------",
  //       JsonData,
  //     );
  //   } else {
  //     setUserInfo(0);
  //   }
  // };

  console.log(
    isLoggedIn,
    "LogedInStatus---------------------------------------------"
  );

  useEffect(() => {
    let data;
    async function tokenFunc() {
      data = await getToken(setTokenFound);
      if (data) {
        postToken(data);
      }
      return data;
    }
    tokenFunc();
  }, [setTokenFound]);

  useEffect(() => {
    if (token) {
      console.log("token --->>> ", token);
    }
  }, [token]);

  useEffect(() => {
    onMessageListener(showNotfication);
  }, [showNotfication]);

  const showNotfication = (payload) => {
    toast.info(payload.notification.body, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    console.log(payload);
  };

  if (initialLoading) {
    return (
      <Backdrop className={classes.backdrop} open={initialLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <>
      <Switch>
        {isLoggedIn == true ? (
          <>
            {!window.location.pathname.includes("/pdf-table") && (
              <Route path="/" component={Admin} />
            )}
            <Route exact path={"/pdf-table/:id"} component={PdfTable} />
            {/* <Redirect from="/pdf-table" to="/pdf-table" /> */}
            {/* <Route exact path="/rtl" component={RTL} /> */}
            {/* <Redirect exact from="/" to="/pdf-table/:id" /> */}
          </>
        ) : (
          <>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/registration" component={RegistrationSteps} />
            <Route path="/reset" component={Reset} />
            <Redirect from="/" to="/login" />
          </>
        )}
      </Switch>
      <Backdrop className={classes.backdrop} open={callInProcess}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

Navigation.propTypes = {
  initialLoading: PropTypes.bool,
  isLoggedIn: PropTypes.bool.isRequired,
  identifyAuthUser: PropTypes.func.isRequired,
  postToken: PropTypes.func.isRequired,
  callInProcess: PropTypes.bool.isRequired,
};

export default Navigation;
