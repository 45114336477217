import { combineReducers } from "redux";
import coreReducer from "./core/core.reducer";
import authReducer from "../modules/auth/store/auth.reducer";
import orderReducer from "../modules/order/store/order.reducer";
import menuReducer from "../modules/menu/store/menu.reducer";
import tableReducer from "../modules/table/store/table.reducer";
import dealsReducer from "modules/deals/store/deals.reducer";

export default combineReducers({
  core: coreReducer,
  auth: authReducer,
  order: orderReducer,
  menu: menuReducer,
  table: tableReducer,
  deals: dealsReducer,
});
