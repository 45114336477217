import { combineEpics, select } from "redux-most";
import * as most from "most";
import {
  getOrdersSuccess,
  GET_ORDERS,
  getOrderDetailsSuccess,
  GET_ORDER_DETAILS,
  GET_ANALYTICS,
  getAnalyticsSuccess,
  GET_ORDER_PAYMENT,
  GET_ORDERS_BY_STATUS,
  getOrderPaymentSuccess,
} from "./order.actions";
import { callInProcess } from "store/core/core.actions";
import { orders, orderDetails } from "core/services/order/order.service";
import { getAnalytics } from "core/services/order/order.service";
import { getOrderPayment } from "core/services/order/order.service";
import { getOrdersByStatus } from "core/services/order/order.service";
import { showToast } from "store/toast/toast.actions";

const ordersEpic = ($actions) =>
  $actions.thru(select(GET_ORDERS)).flatMap((action) => {
    return most
      .fromPromise(orders(action.payload))
      .flatMap((response) => {
        console.log(response);
        return most.from([
          getOrdersSuccess({
            type: action.payload.type,
            orders: response,
          }),
          callInProcess(false),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const ordersDetailsEpic = ($actions) =>
  $actions.thru(select(GET_ORDER_DETAILS)).flatMap((action) => {
    return most
      .fromPromise(orderDetails(action.payload))
      .flatMap((response) => {
        console.log(response);
        return most.from([
          getOrderDetailsSuccess({
            orderDetails: response,
          }),
          callInProcess(false),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const getAnalyticsEpic = ($actions) =>
  $actions.thru(select(GET_ANALYTICS)).flatMap((action) => {
    return most
      .fromPromise(getAnalytics({ recent: action.payload }))
      .flatMap((response) => {
        return most.from([
          getAnalyticsSuccess({
            analytics: response,
          }),
          callInProcess(false),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const getOrderPaymentEpic = ($actions) =>
  $actions.thru(select(GET_ORDER_PAYMENT)).flatMap((action) => {
    return most
      .fromPromise(
        getOrderPayment({
          params: action.payload.params,
          id: action.payload.id,
        })
      )
      .flatMap((response) => {
        return most.from([
          getOrderPaymentSuccess({
            payments: response,
          }),
          callInProcess(false),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const getOrdersByStatusEpic = ($actions) =>
  $actions.thru(select(GET_ORDERS_BY_STATUS)).flatMap((action) => {
    return most
      .fromPromise(
        getOrdersByStatus({
          params: action.payload.params,
          id: action.payload.id,
        })
      )
      .flatMap((response) => {
        console.log(response);
        if (action.payload.getOrdersByTab) {
          action.payload.getOrdersByTab();
        }
        return most.from([
          showToast({
            message: "Order status updated successfully",
            type: "success",
          }),
          callInProcess(false),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

export default combineEpics([
  ordersEpic,
  ordersDetailsEpic,
  getAnalyticsEpic,
  getOrderPaymentEpic,
  getOrdersByStatusEpic,
]);
