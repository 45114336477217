export const PaymentMethod = {
  CASH: 1,
  CARD: 2,
  WALLET: 3,
};

export const PaymentMethodMapping = {
  1: "CASH",
  2: "CARD",
  3: "WALLET",
};

export const OrderStatus = {
  Pending: 1,
  Accept: 2,
  Reject: 3,
  Preparing: 4,
  Ready: 5,
  Completed: 6,
};

export const OrderStatusMapping = {
  1: "Pending",
  2: "Accept",
  3: "Reject",
  4: "Preparing",
  5: "Ready",
  6: "Completed",
};

export const OrderStatusColorMapping = {
  1: "#FFB655",
  2: "#15C070",
  3: "#FF5023",
  4: "#15C070",
  5: "#C475EA",
  6: "#15C070",
};
