import forge from "mappersmith";
import { apiBaseUrl } from "../../../constants/api-baseUrl";
import { ServerErrorMiddleware } from "../../middleware/server-error-middleware";
import { AccessTokenMiddleware } from "../../middleware/access-token-middleware";
import EncodeJson from "mappersmith/middleware/encode-json";
import { ServerResponseMiddleware } from "../../middleware/server-response-middleware";

const ServerErrors = ServerErrorMiddleware();
const ServerResponse = ServerResponseMiddleware();
const AccesTokenMiddleware = AccessTokenMiddleware();
const client = forge({
  host: apiBaseUrl,
  middleware: [ServerErrors, ServerResponse, AccesTokenMiddleware, EncodeJson],
  resources: {
    Deal: {
      getDeals: {
        path: "/restaurent/deal",
        method: "get",
      },
      createDeal: {
        path: "/restaurent/deal",
        method: "post",
        bodyAttr: "params",
      },
    },
  },
});

export const getDeals = () => {
  return client.Deal.getDeals();
};

export const createDeal = (params) => {
  console.log(params);
  return client.Deal.createDeal({ params });
};
