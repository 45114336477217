import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RegistrationSteps from "./RegistrationSteps";
import { registration } from "./../store/auth.actions";
import { callInProcess } from "store/core/core.actions";
import { getCategories } from "../../menu/store/menu.actions";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      registration,
      callInProcess,
      getCategories,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  const { core, menu } = state;
  return {
    callInProcess: core.callInProcess,
    categories: menu.categories,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationSteps);
