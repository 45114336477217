/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import history from "./history";
import Navigation from "./navigation/index";
import { store } from "./index";

function App() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#15C070",
      },
      secondary: {
        main: "#15C070",
      },
    },
  });

  return (
    <div className="App">
      <BrowserRouter history={history}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Navigation />
          </ThemeProvider>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Provider>
      </BrowserRouter>
    </div>
  );
}

App.propTypes = {};

export default App;
