export const orderStatus = [
  "Pending",
  "Accept",
  "Reject",
  "Preparing",
  "Ready",
  "Completed",
];

export const orderColumns = [
  { id: "table", label: "Table#", minWidth: 100 },
  { id: "tableName", label: "Table Name", minWidth: 120 },
  { id: "customerName", label: "Customer Name", minWidth: 120 },
  {
    id: "orderList",
    label: "Order List",
    minWidth: 120,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "orderAmount",
    label: "Order Amount",
    minWidth: 120,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "orderTime",
    label: "Order Time",
    minWidth: 120,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "paymentStatus",
    label: "Payment Status",
    minWidth: 120,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "orderStatus",
    label: "Order Status",
    minWidth: 120,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];

export const dashboardBoxes = [
  {
    number: "15",
    status: "Active Order",
    color: "#15C070",
    property: "activeOrder",
  },
  {
    number: "03",
    status: "Pending",
    color: "#FFB655",
    property: "pendingOrder",
  },
  {
    number: "26",
    status: "Completed",
    color: "#C475EA",
    property: "completedOrder",
  },
  {
    number: "05",
    status: "Empty Tables",
    color: "#518EF8",
    property: "emptyTables",
  },
  {
    number: "$5k",
    status: "Cash Payment",
    color: "#FF5652",
    property: "cashOrder",
  },
  {
    number: "$15k",
    status: "CC Payment",
    color: "#FFD422",
    property: "ccOrder",
  },
];

export const restaurantTiming = ["Morning", "Evening", "Night"];

export const restaurantCategories = ["Chinese", "Continental", "Buger Cafe"];
