import React from "react";
import { PropTypes } from "prop-types";

const Maps = ({ getLocation, selectedLat, selectedLng, restaurantName }) => {
  const mapRef = React.useRef(null);
  React.useEffect(() => {
    let google = window.google;
    let map = mapRef.current;
    let lat = selectedLat;
    let lng = selectedLng;
    const myLatlng = new google.maps.LatLng(lat, lng);
    const mapOptions = {
      zoom: 12,
      center: myLatlng,
      scrollwheel: false,
      zoomControl: true,
      styles: [
        {
          featureType: "water",
          stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: "#0088ff" }],
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [
            { hue: "#ff0000" },
            { saturation: -100 },
            { lightness: 99 },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#808080" }, { lightness: 54 }],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.fill",
          stylers: [{ color: "#ece2d9" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry.fill",
          stylers: [{ color: "#ccdca1" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#767676" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#ffffff" }],
        },
        { featureType: "poi", stylers: [{ visibility: "off" }] },
        {
          featureType: "landscape.natural",
          elementType: "geometry.fill",
          stylers: [{ visibility: "on" }, { color: "#b8cb93" }],
        },
        { featureType: "poi.park", stylers: [{ visibility: "on" }] },
        {
          featureType: "poi.sports_complex",
          stylers: [{ visibility: "on" }],
        },
        { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
        {
          featureType: "poi.business",
          stylers: [{ visibility: "simplified" }],
        },
      ],
    };

    map = new google.maps.Map(map, mapOptions);

    const marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      draggable: true,
      animation: google.maps.Animation.DROP,
      title: "Cerwiz",
    });

    const contentString = `<div class="info-window-content"><h3>${restaurantName}</h3></div>`;

    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    google.maps.event.addListener(marker, "dragend", function () {
      getLocation({
        lat: marker.getPosition().lat(),
        lng: marker.getPosition().lng(),
      });
      infowindow.open(map, marker);
    });
  });
  return (
    <>
      <div style={{ height: `92vh` }} ref={mapRef}></div>
    </>
  );
};

Maps.propTypes = {
  getLocation: PropTypes.func,
  selectedLat: PropTypes.number,
  selectedLng: PropTypes.number,
  restaurantName: PropTypes.string,
};

function moviePropsAreEqual() {
  return true;
}
export default React.memo(Maps, moviePropsAreEqual);
