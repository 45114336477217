import React, { useEffect } from "react";
import "./OrderDetailsModal.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import SuccessImage from "assets/icons/success-white.png";
// import FoodItem from "assets/img/food-item.png";
// import CancelImage from "assets/icons/cross-white.png";
// import Fab from "@material-ui/core/Fab";
import { PropTypes } from "prop-types";
import SelectBox from "components/SelectBox/SelectBox";
import { orderStatus } from "constants/constant";

const styles = (theme) => ({
  root: {
    margin: 0,
    borderBottom: "none",
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: "25px",
    top: "25px",
    padding: "0px",
    width: "16px",
    height: "16px",
    color: theme.palette.grey[500],
    "&:hover": {
      background: "transparent",
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose } = props;
  return (
    // <MuiDialogTitle disableTypography className={classes.root} {...other}>
    //   {onClose ? (
    //   ) : null}
    // </MuiDialogTitle>
    <IconButton
      aria-label="close"
      className={classes.closeButton}
      onClick={onClose}
    >
      <CloseIcon />
    </IconButton>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function OrderDetailsModal({
  showModal,
  setShowModal,
  getOrderDetails,
  orderDetails,
  getOrdersByStatus,
  callInProcess,
  isLoading,
  getOrdersByTab,
}) {
  useEffect(() => {
    callInProcess(true);
    getOrderDetails({ id: 1 });
  }, [getOrderDetails, callInProcess]);

  useEffect(() => {
    console.log(orderDetails);
    console.log("orderDetails ====>" + showModal);
  }, [orderDetails]);

  const useStyles = makeStyles(() => ({
    backDrop: {
      backdropFilter: "blur(3px)",
      backgroundColor: "rgba(50,50,30,0.1)",
    },
  }));

  const changeStatus = (val) => {
    if (val === 0) {
      return;
    }
    getOrdersByStatus({ status: val }, showModal.id, getOrdersByTab);
    setShowModal(null);
  };

  const classes = useStyles();

  return (
    <Dialog
      onClose={() => setShowModal(null)}
      aria-labelledby="customized-dialog-title"
      open={showModal}
      fullWidth="md"
      maxWidth="md"
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => setShowModal(null)}
      ></DialogTitle>
      <DialogContent>
        <div className="dailog-container order-details-modal">
          {isLoading ? (
            <div className="loading">Loading...</div>
          ) : (
            <>
              <div className="dailog-left-container">
                <div className="sectionB">
                  <div className="sectionB-item">
                    <h5>Table Name:</h5>
                    <p>{showModal?.table?.name}</p>
                  </div>
                  <div className="sectionB-item">
                    <h5>Table Number:</h5>
                    <p>{showModal?.table?.id}</p>
                  </div>
                  <div className="sectionB-item">
                    <h5>Table Capacity:</h5>
                    <p>{showModal?.table?.capacity}</p>
                  </div>
                  <div className="sectionB-item">
                    <h5>Available:</h5>
                    <p>{showModal?.table?.isBooked ? "Yes" : "No"}</p>
                  </div>
                </div>
                <div className="sectionC">
                  {/* <Fab size="small" color="secondary" aria-label="add">
                    <img src={SuccessImage} className="fab-icons" />
                  </Fab>
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="add"
                    className="sectionC-cancle-image"
                  >
                    <img src={CancelImage} className="fab-icons" />
                  </Fab> */}
                  <SelectBox
                    menuList={orderStatus}
                    value={showModal.status}
                    label="Order Status"
                    onSelect={changeStatus}
                  />
                </div>
              </div>
              <div className="dailog-right-container">
                <div className="sectionA">
                  <h3 className="main-title">
                    Item List ({showModal?.items?.length})
                  </h3>
                </div>
                {showModal?.items?.map((item) => (
                  <>
                    <div className="sectionB">
                      <div className="food-item">
                        <img src={item.img} />
                        <div className="food-item-details">
                          <p className="food-item-price">${item.price}</p>
                          <p className="food-item-name">
                            {item.name}
                            <div className="food-item-quantity">
                              {item.quantity}
                            </div>
                          </p>
                          <p className="food-item-desc">
                            <b>Notes:</b> {item.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
OrderDetailsModal.propTypes = {
  showModal: PropTypes.any,
  setShowModal: PropTypes.Func,
  getOrderDetails: PropTypes.Func,
  getOrdersByStatus: PropTypes.Func,
  orderDetails: PropTypes.object,
  callInProcess: PropTypes.func,
  getOrdersByTab: PropTypes.func,
  isLoading: PropTypes.bool,
};
