import React, { useEffect } from "react";
import "./Menu.css";
import TextField from "@material-ui/core/TextField";
import CustomButton from "../../auth/CustomButton/index";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CreateMenuModal from "views/CreateMenuModal";
import MenuCard from "views/MenuCard";
import SearchIcon from "assets/icons/search-icon.png";
import CreateNewTabModal from "views/CreateNewTabModal";
import CrossIcon from "assets/icons/cross-icon.svg";
import { PropTypes } from "prop-types";
import ConfirmationModal from "views/ConfirmationModal";
import { Button } from "@material-ui/core";

function TabPanel(props) {
  // eslint-disable-next-line react/prop-types
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Menu({
  // getMenus,
  callInProcess,
  restaurant,
  getRestaurantById,
  deleteTab,
  // getTabs,
  // tabs,
  // getCategories,
}) {
  const [showCreateMenuModal, setShowCreateMenuModal] = React.useState(null);
  const [showCreateNewTabModal, setShowCreateNewTabModal] = React.useState(
    false
  );
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(
    null
  );
  const [menuSearchValue, setMenuSearchValue] = React.useState("");
  const [activeTab, setActiveTab] = React.useState(0);
  const [allMenus, setMenus] = React.useState([]);
  const handleChange = (event, newValue) => {
    console.log(menuSearchValue);
    setMenuSearchValue("");
    setActiveTab(newValue);
  };

  useEffect(() => {
    callInProcess(true);
    // getMenus({ type: "menus", params: { page: 1, limit: 10 } });
    // getTabs({ type: "tabs", params: { page: 1, limit: 10 } });
    getRestaurantById();
  }, []);

  useEffect(() => {
    console.log("restaurant -->> ", restaurant);
    // console.log(menus);
    if (restaurant) {
      console.log("SubCategories-------------------", restaurant.subCategories);
      setMenus([...(restaurant.subCategories || [])]);
    }
  }, [restaurant]);

  const navigateToCreateMenuModal = () => setShowCreateMenuModal(true);
  const navigateToCreateNewTab = () => setShowCreateNewTabModal(true);

  const onSearch = (event) => {
    console.log(allMenus);
    const searchValue = event.target.value.toLowerCase();
    const tempMenus = JSON.parse(JSON.stringify(restaurant.subCategories));
    tempMenus[activeTab].items = tempMenus[activeTab].items.filter(
      (menu) => menu.name.toLowerCase().indexOf(searchValue) > -1
    );
    setMenuSearchValue(searchValue);
    setMenus(tempMenus);
  };
  const deleteSelectedTab = () => {
    if (showConfirmationModal) {
      deleteTab(showConfirmationModal.id);
      setShowConfirmationModal(null);
    }
  };
  return (
    <div className="menu-container">
      <div className="section1 menu-section1">
        <div>
          <h3 className="main-title">Manage Menu</h3>
        </div>
        <div className="menu-section1-right-section">
          <CustomButton
            title="Create New Tab"
            navigateTo={navigateToCreateNewTab}
            customStyles={{
              width: "175px",
              height: "36px",
              borderRadius: "8px",
              padding: "0",
            }}
          />
          <CustomButton
            title="Create New Menu"
            navigateTo={navigateToCreateMenuModal}
            customStyles={{
              width: "175px",
              height: "36px",
              borderRadius: "8px",
              padding: "0",
              margin: "0px 30px",
            }}
          />
          <TextField
            id="outlined-secondary"
            type="text"
            variant="outlined"
            value={menuSearchValue}
            size="small"
            className="menu-section1-right-section-input"
            placeholder="Search"
            InputProps={{
              startAdornment: <img src={SearchIcon} className="search-icon" />,
            }}
            onChange={onSearch}
          />
        </div>
      </div>
      <div className="menu-tab-container">
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
          variant="scrollable"
          scrollButtons="auto"
          className="tabs menu-tabs"
        >
          {allMenus.map((tab, index) => (
            <Tab
              label={tab.name}
              key={index}
              className="menu-tabs"
              component={() => (
                <div className="tab-container">
                  <Button
                    className={[
                      index === activeTab && "active-tab-title",
                      "tab-title",
                    ]}
                    onClick={() => handleChange("", index)}
                  >
                    {tab.name}
                  </Button>
                  <img
                    src={CrossIcon}
                    className="menu-tab-delete"
                    onClick={() => setShowConfirmationModal(tab)}
                  />
                </div>
              )}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        <div className="menu-section2">
          {allMenus[activeTab]?.items?.length ? (
            <>
              {allMenus.map((_, index) => (
                <TabPanel value={activeTab} index={index} key={index}>
                  <MenuCard
                    menus={allMenus[activeTab].items}
                    showCreateMenuModal={showCreateMenuModal}
                    setShowCreateMenuModal={setShowCreateMenuModal}
                  />
                </TabPanel>
              ))}
            </>
          ) : (
            <div className="no-items-found">No Menus Found</div>
          )}
        </div>
      </div>
      {showCreateMenuModal ? (
        <CreateMenuModal
          showCreateMenuModal={showCreateMenuModal}
          setShowCreateMenuModal={setShowCreateMenuModal}
          menus={allMenus}
          index={activeTab}
        />
      ) : (
        <></>
      )}
      {showCreateNewTabModal ? (
        <CreateNewTabModal
          showCreateNewTabModal={showCreateNewTabModal}
          setShowCreateNewTabModal={setShowCreateNewTabModal}
        />
      ) : (
        <></>
      )}
      {showConfirmationModal ? (
        <ConfirmationModal
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={setShowConfirmationModal}
          message={`Are you sure to delete`}
          submit={deleteSelectedTab}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
Menu.propTypes = {
  callInProcess: PropTypes.func.isRequired,
  restaurant: PropTypes.any.isRequired,
  getRestaurantById: PropTypes.func.isRequired,
  deleteTab: PropTypes.func,
};
