import forge from "mappersmith";
import { apiBaseUrl } from "../../../constants/api-baseUrl";
import { ServerErrorMiddleware } from "../../middleware/server-error-middleware";
import { AccessTokenMiddleware } from "../../middleware/access-token-middleware";
import EncodeJson from "mappersmith/middleware/encode-json";
import { ServerResponseMiddleware } from "../../middleware/server-response-middleware";

const ServerErrors = ServerErrorMiddleware();
const ServerResponse = ServerResponseMiddleware();
const AccesTokenMiddleware = AccessTokenMiddleware();
console.log(
  "ApiBaseURL---------------------------------------------------",
  apiBaseUrl
);
const client = forge({
  host: apiBaseUrl,
  middleware: [ServerErrors, ServerResponse, AccesTokenMiddleware, EncodeJson],
  resources: {
    Auth: {
      login: {
        path: "/auth/login",
        method: "post",
        bodyAttr: "loginParams",
      },
      register: {
        path: "/auth/register",
        method: "post",
        bodyAttr: "registerParams",
      },
      restaurantRegistration: {
        path: "/restaurent",
        method: "post",
        bodyAttr: "registrationParams",
      },
      resendCode: {
        path: "/buyer/check-phone-verification",
        method: "post",
        bodyAttr: "resendParams",
      },
      verfifyCode: {
        path: "/buyer/check-phone-verification",
        method: "post",
        bodyAttr: "verfifyParams",
      },
      logout: {
        path: "/logout",
        method: "get",
      },
      getRestaurant: {
        path: "/restaurent/{id}",
        method: "get",
      },
      editRestaurantInfo: {
        path: "/restaurent/{id}",
        method: "put",
        bodyAttr: "params",
      },
      reset: {
        path: "/auth/forgetPassword",
        method: "post",
        bodyAttr: "email",
      },
      updatePassword: {
        path: "/user/resetPassword",
        method: "post",
        bodyAttr: "params",
      },
    },
  },
});

export const login = (loginParams) => {
  console.log("service", loginParams);
  return client.Auth.login({ loginParams });
};

export const signup = (registerParams) => {
  return client.Auth.register({ registerParams });
};

export const restaurantRegistration = (registrationParams) => {
  return client.Auth.restaurantRegistration({ registrationParams });
};

export const resendCode = (resendParams) => {
  return client.Auth.resendCode({ resendParams });
};

export const verfifyCode = (verfifyParams) => {
  return client.Auth.verfifyCode({ verfifyParams });
};

export const logout = () => {
  return client.Auth.logout();
};

export const getRestaurantByIdService = (id) => {
  console.log(id);
  return client.Auth.getRestaurant({ id });
};

export const editRestaurantInfo = ({ params, id }) => {
  return client.Auth.editRestaurantInfo({ params, id });
};

export const reset = (email) => {
  return client.Auth.reset({ email });
};

export const updatePassword = (params) => {
  console.log(params);
  return client.Auth.updatePassword({ params });
};
