import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ItemDetailsModal from "./ItemDetailsModal";

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    categories: auth.restaurant.subCategories,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemDetailsModal);
