import {
  GET_ANALYTICS_SUCCESS,
  GET_ORDERS_BY_STATUS_SUCCESS,
  GET_ORDERS_SUCCESS,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_PAYMENT_SUCCESS,
} from "./order.actions";

const INITIAL_STATE = {
  orders: {
    data: [],
  },
  analytics: {},
  recentOrders: {
    data: [],
  },
  payments: {
    data: [],
  },
  orderDetails: {},
};

const orderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        [action.payload.type]: action.payload.orders,
      };
    case GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload.orderDetails,
      };
    case GET_ANALYTICS_SUCCESS:
      return {
        ...state,
        analytics: action.payload.analytics,
      };
    case GET_ORDER_PAYMENT_SUCCESS:
      return {
        ...state,
        payments: action.payload.payments,
      };
    case GET_ORDERS_BY_STATUS_SUCCESS:
      return {
        ...state,
        [action.payload.type]: action.payload.orders,
      };
    default:
      return state;
  }
};

export default orderReducer;
