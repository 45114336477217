export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILURE = "GET_ORDERS_FAILURE";
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const GET_ORDER_DETAILS_SUCCESS = "GET_ORDER_DETAILS_SUCCESS";
export const GET_ORDER_DETAILS_FAILURE = "GET_ORDER_DETAILS_FAILURE";
export const GET_ANALYTICS = "GET_ANALYTICS";
export const GET_ANALYTICS_SUCCESS = "GET_ANALYTICS_SUCCESS";
export const GET_ANALYTICS_FAILURE = "GET_ANALYTICS_FAILURE";
export const GET_ORDER_PAYMENT = "GET_ORDER_PAYMENT";
export const GET_ORDER_PAYMENT_SUCCESS = "GET_ORDER_PAYMENT_SUCCESS";
export const GET_ORDER_PAYMENT_FAILURE = "GET_ORDER_PAYMENT_FAILURE";
export const GET_ORDERS_BY_STATUS = "GET_ORDERS_BY_STATUS";
export const GET_ORDERS_BY_STATUS_SUCCESS = "GET_ORDERS_BY_STATUS_SUCCESS";
export const GET_ORDERS_BY_STATUS_FAILURE = "GET_ORDERS_BY_STATUS_FAILURE";

export const getOrders = ({ type, params }) => ({
  type: GET_ORDERS,
  payload: { type, params },
});

export const getOrdersSuccess = ({ orders, type }) => ({
  type: GET_ORDERS_SUCCESS,
  payload: { orders, type },
});

export const getOrderDetails = ({ id }) => ({
  type: GET_ORDER_DETAILS,
  payload: { id },
});

export const getOrderDetailsSuccess = ({ orderDetails }) => ({
  type: GET_ORDER_DETAILS_SUCCESS,
  payload: { orderDetails },
});

export const getAnalytics = () => ({
  type: GET_ANALYTICS,
  payload: true,
});

export const getAnalyticsSuccess = ({ analytics }) => ({
  type: GET_ANALYTICS_SUCCESS,
  payload: { analytics },
});

export const getOrderPayment = (params, id) => ({
  type: GET_ORDER_PAYMENT,
  payload: { params, id },
});

export const getOrderPaymentSuccess = ({ payments }) => ({
  type: GET_ORDER_PAYMENT_SUCCESS,
  payload: { payments },
});

export const getOrdersByStatus = (params, id, getOrdersByTab) => ({
  type: GET_ORDERS_BY_STATUS,
  payload: { params, id, getOrdersByTab },
});

export const getOrdersByStatusSuccess = ({ orders, type }) => ({
  type: GET_ORDERS_BY_STATUS_SUCCESS,
  payload: { orders, type },
});
