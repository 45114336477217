import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MenuCard from "./MenuCard";
import { deleteMenu } from "modules/menu/store/menu.actions";

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteMenu,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MenuCard);
