import React, { useState, useEffect } from "react";
import "./CreateTableModal.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
// import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import QRCodeImage from "assets/img/QR-code.png";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import CustomButton from "../../modules/auth/CustomButton/CustomButton";
import { PropTypes } from "prop-types";
import { setFieldValues } from "constants/validation";
import Error from "components/Error/Error";
import { checkFormValid } from "constants/validation";

const styles = (theme) => ({
  root: {
    margin: 0,
    borderBottom: "none",
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: "25px",
    top: "25px",
    padding: "0px",
    width: "16px",
    height: "16px",
    color: theme.palette.grey[500],
    "&:hover": {
      background: "transparent",
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose } = props;
  return (
    // <MuiDialogTitle disableTypography className={classes.root} {...other}>
    //   {onClose ? (
    //   ) : null}
    // </MuiDialogTitle>
    <IconButton
      aria-label="close"
      className={classes.closeButton}
      onClick={onClose}
    >
      <CloseIcon />
    </IconButton>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CreateTableModal({
  showCreateTableModal,
  setShowCreateTableModal,
  createTable,
  editTable,
  callInProcess,
}) {
  const formLabels = {
    name: "Name",
    capacity: "Capacity",
  };

  let [form, setForm] = useState({
    name: "",
    capacity: "1",
  });

  const [formErrorState, setFormErrorState] = useState({
    name: "",
    capacity: "",
  });

  useEffect(() => {
    if (showCreateTableModal?.id) {
      setForm(showCreateTableModal);
    }
  }, [showCreateTableModal]);

  const closeModal = () => {
    setShowCreateTableModal(false);
  };

  const submit = () => {
    if (!checkFormValid(form, formErrorState, setFormErrorState, formLabels)) {
      return;
    }
    form = {
      ...form,
      capacity: Number(form.capacity),
    };
    callInProcess(true);
    if (setShowCreateTableModal?.id) {
      editTable({ ...form, resId: setShowCreateTableModal.id });
    } else {
      createTable(form);
    }
    closeModal();
  };

  const checkNumberInputValue = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const title = showCreateTableModal?.id ? "Edit" : "Create";

  const useStyles = makeStyles(() => ({
    backDrop: {
      backdropFilter: "blur(3px)",
      backgroundColor: "rgba(50,50,30,0.1)",
    },
  }));

  const classes = useStyles();

  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={showCreateTableModal}
      fullWidth="sm"
      maxWidth="sm"
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={closeModal}
      ></DialogTitle>
      <DialogContent>
        <div className="dailog-container create-table-modal">
          <div className="dailog-left-container">
            <div className="sectionB">
              <div className="create-menu-sectionB-item">
                <h3 className="main-title">{title} Table</h3>
                <div className="create-menu-modal-input-sectionA">
                  <p className="create-menu-modal-input-label">Table Number</p>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className="create-menu-modal-input"
                  >
                    <TextField
                      id="outlined-adornment-password"
                      type="text"
                      variant="outlined"
                      size="small"
                      placeholder="Dinner"
                      value={form.name}
                      onChange={(e) =>
                        setFieldValues(
                          "name",
                          e.target.value,
                          "",
                          formLabels,
                          setFormErrorState,
                          form,
                          setForm
                        )
                      }
                    />
                    {formErrorState.name && (
                      <Error error={formErrorState.name} />
                    )}
                  </FormControl>
                </div>
                <div className="create-menu-modal-input-sectionC table-person-input">
                  <p className="create-menu-modal-input-label">Persons</p>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className="create-menu-modal-input"
                  >
                    <TextField
                      id="outlined-adornment-password"
                      type="number"
                      variant="outlined"
                      size="small"
                      placeholder="Sitting capacity"
                      value={form.capacity}
                      onKeyPress={checkNumberInputValue}
                      InputProps={{ inputProps: { min: 1 } }}
                      onChange={(e) =>
                        setFieldValues(
                          "capacity",
                          e.target.value,
                          "",
                          formLabels,
                          setFormErrorState,
                          form,
                          setForm
                        )
                      }
                    />
                    {formErrorState.capacity && (
                      <Error error={formErrorState.capacity} />
                    )}
                  </FormControl>
                </div>
                {/* <div className="create-menu-right-first-inner-section3">
                  <button className="create-menu-right-first-inner-section3-button table-generate-code-button">
                    Generate Table QR Code
                  </button>
                </div> */}
                <div className="create-menu-right-second-section table-action-buttons">
                  <CustomButton
                    title={showCreateTableModal?.id ? "Update" : "Create"}
                    customStyles={{ width: "131px", marginRight: "20px" }}
                    navigateTo={submit}
                  />
                  <CustomButton
                    title="Cancel"
                    customStyles={{
                      width: "98px",
                      backgroundColor: "#ffffff",
                      color: "#42526E",
                      border: "1px solid rgba(66, 82, 110, 0.7)",
                    }}
                    navigateTo={closeModal}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="dailog-right-container create-table-right-container">
            <div className="sectionA">
              <h3 className="create-table-qr-title">QR Code Card</h3>
            </div>
            <div className="create-menu-right-first-section qr-code-image">
              <img src={QRCodeImage} />
            </div>
            <div className="create-table-download-pdf">
              <span className>Download PDF Now!</span>
            </div>
          </div> */}
        </div>
      </DialogContent>
    </Dialog>
  );
}
CreateTableModal.propTypes = {
  showCreateTableModal: PropTypes.boolean,
  setShowCreateTableModal: PropTypes.Func,
  createTable: PropTypes.func,
  editTable: PropTypes.func,
  callInProcess: PropTypes.func.isRequired,
};
