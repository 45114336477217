import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Deals from "./Deals";
import { getDeals, createDeal } from "./../store/deals.actions";
import { callInProcess } from "store/core/core.actions";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDeals,
      createDeal,
      callInProcess,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  const { deals } = state;
  return {
    deals: deals.deals,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Deals);
