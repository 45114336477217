/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// core components

const useStyles = makeStyles({
  errorContent: {
    color: "red",
  }
});

export default function Error(props) {
  const classes = useStyles();
  return (
    <div className={classes.errorContent}>{props.error}</div>
  );
}
