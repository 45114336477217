import React from "react";
import "./PaymentSettings.css";
import ColorBox from "views/ColorBox";
import StripeImage from "assets/img/stripe_logo.png";
// import AppleImage from "assets/img/apple_logo.png";
// import BlueNavyImage from "assets/img/blue_navy.png";
// import GooglePlayImage from "assets/img/google_play.png";

export default function PaymentSettings() {
  const boxes = [
    {
      number: "15",
      status: "Active Order",
      color: "#15C070",
    },
    {
      number: "03",
      status: "Pending",
      color: "#FFB655",
    },
    {
      number: "26",
      status: "Completed",
      color: "#C475EA",
    },
  ];
  const payments = [
    {
      image: StripeImage,
    },
    // {
    //   image: AppleImage,
    // },
    // {
    //   image: BlueNavyImage,
    // },
    // {
    //   image: GooglePlayImage,
    // },
  ];
  return (
    <div className="section3">
      <div className="color-box-section">
        <ColorBox boxes={boxes} showArrow={true} />
      </div>
      <div className="payments-cards-section">
        <h3 className="main-title">Integrate Your Payment Online</h3>
        <div className="payments-section">
          {payments.map((payment, index) => (
            <div className="payment-card" key={index}>
              <img src={payment.image} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
PaymentSettings.propTypes = {};
