const S3_BUCKET = "cerwizBucket";
const REGION = "us-east-2";
const ACCESS_KEY = "DO009ZV9VZDCJJDWNUNK";
const SECRET_ACCESS_KEY = "fmkQVHt/3v+XDySNyMwgojWs27yIICg56503wcIcFrI";

export const config = {
  bucketName: S3_BUCKET,
  dirName: "upload",
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  awsUrl: "sfo3.digitaloceanspaces.com",
};
