import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ShowQRCodeModal from "./ShowQRCodeModal";
import { callInProcess } from "store/core/core.actions";
import { createTab } from "./../../modules/menu/store/menu.actions";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createTab,
      callInProcess,
    },
    dispatch
  );

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowQRCodeModal);
