import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import "./Orders.css";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import OrderDetailsModal from "views/OrderDetailsModal";
import SearchIcon from "assets/icons/search-icon.png";
import SelectBox from "components/SelectBox/SelectBox";
import { orderStatus } from "constants/constant";
import OrdersTable from "../OrdersTable";

function TabPanel(props) {
  // eslint-disable-next-line react/prop-types
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Orders({
  getOrders,
  orders,
  callInProcess,
  recentOrders,
}) {
  const [value, setValue] = React.useState(0);
  const [showModal, setShowModal] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [allOrders, setOrders] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [status, setStatus] = React.useState(0);
  const [params, setParams] = React.useState({
    page: 1,
    limit: rowsPerPage,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setParams((val) => ({
      ...val,
      page: newPage + 1,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setParams((val) => ({
      ...val,
      limit: +event.target.value,
    }));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const queryParams = {
      ...params,
      search: "",
      page: 1,
    };
    if (queryParams.status) {
      delete queryParams.status;
    }
    setPage(0);
    setSearch("");
    setStatus(0);
    setValue(newValue);
    getOrdersByTab(queryParams);
  };

  useEffect(() => {
    setOrders(orders.data);
  }, [orders]);

  useEffect(() => {
    console.log(recentOrders);
    setOrders(recentOrders.data);
  }, [recentOrders]);

  const changeStatus = (val) => {
    setStatus(val);
    callInProcess(true);
    const queryParams = {
      ...params,
      page: 1,
      status: val,
    };
    if (val === 0) {
      delete queryParams.status;
    }
    getOrders({
      type: value === 0 ? "recentOrders" : "orders",
      params: queryParams,
    });
    setParams(queryParams);
  };

  const onSearch = (event) => {
    setSearch(event.target.value);
    const queryParams = {
      ...params,
      search: event.target.value,
    };
    if (value === 0) {
      getOrders({
        type: "recentOrders",
        params: queryParams,
      });
    } else if (value === 1) {
      getOrders({
        type: "orders",
        params: queryParams,
      });
    }
    setParams(queryParams);
  };

  const getOrdersByTab = (newParams = {}) => {
    callInProcess(true);
    const queryParams = {
      ...params,
      ...newParams,
      from: 0,
      to: 0,
    };
    if (newParams.page) {
      delete queryParams.status;
    }
    const yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
    if (value === 0) {
      getOrders({
        type: "recentOrders",
        params: queryParams,
      });
    } else if (value === 1) {
      const date = new Date();
      const lastMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        date.getDate()
      );
      queryParams.from = Math.floor(lastMonth.getTime() / 1000);
      queryParams.to = Math.floor(yesterday.getTime() / 1000);
      getOrders({
        type: "orders",
        params: queryParams,
      });
    }
    setParams(queryParams);
  };

  return (
    <div className="orders-container">
      <div className="header-container">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <SelectBox
            value={status}
            menuList={orderStatus}
            label="Order Status"
            onSelect={changeStatus}
            customStyleForInput={{ backgroundColor: "#000", color: "#ffffff" }}
          />
          <TextField
            id="outlined-secondary"
            value={search}
            type="text"
            variant="outlined"
            size="small"
            className="input orders-search-input"
            placeholder="Search"
            InputProps={{
              startAdornment: <img src={SearchIcon} className="search-icon" />,
            }}
            onChange={onSearch}
          />
        </div>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
          className="tabs"
        >
          <Tab label="Today Orders" {...a11yProps(0)} />
          <Tab label="Order History" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <OrdersTable
            getOrders={getOrders}
            orders={recentOrders}
            callInProcess={callInProcess}
            allOrders={allOrders}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            page={page}
            setShowModal={setShowModal}
            type={"today"}
            getOrdersByTab={getOrdersByTab}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OrdersTable
            getOrders={getOrders}
            orders={orders}
            callInProcess={callInProcess}
            allOrders={allOrders}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            page={page}
            setShowModal={setShowModal}
            getOrdersByTab={getOrdersByTab}
            type={"last-month"}
          />
        </TabPanel>
      </div>
      {showModal ? (
        <OrderDetailsModal
          showModal={showModal}
          setShowModal={setShowModal}
          getOrdersByTab={getOrdersByTab}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
Orders.propTypes = {
  getOrders: PropTypes.func.isRequired,
  callInProcess: PropTypes.func.isRequired,
  orders: PropTypes.any.isRequired,
  recentOrders: PropTypes.any.isRequired,
};
