import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Login from "./Login";
import { login } from "./../store/auth.actions";
import { callInProcess } from "store/core/core.actions";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login,
      callInProcess,
    },
    dispatch
  );

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
