import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Dashboard from "./Dashboard";
import {
  getOrders,
  getAnalytics,
  getOrdersByStatus,
} from "../../modules/order/store/order.actions";
import { callInProcess } from "store/core/core.actions";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOrders,
      getAnalytics,
      getOrdersByStatus,
      callInProcess,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  const { order } = state;
  return {
    recentOrders: order.recentOrders,
    analytics: order.analytics,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
