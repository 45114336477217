import React from "react";
import "./SelectBox.css";
import { PropTypes } from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

export default function SelectBox({
  menuList,
  label,
  customStyle,
  customStyleForInput,
  onSelect,
  value,
  icon,
}) {
  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      width: "100%",
      borderRadius: 8,
      position: "relative",
      backgroundColor: customStyleForInput?.backgroundColor
        ? customStyleForInput?.backgroundColor
        : theme.palette.background.paper,
      color: customStyleForInput?.color ? customStyleForInput?.color : "#000",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 8,
        border: "1px solid #15C070",
      },
    },
  }))(InputBase);
  const [date, setDate] = React.useState(0);
  const handleChange = (event) => {
    setDate(event.target.value);
    if (onSelect) {
      onSelect(event.target.value);
    }
  };
  return (
    <Select
      labelId="demo-customized-select-label"
      id="demo-customized-select"
      value={value >= 0 ? value : date}
      style={customStyle}
      onChange={handleChange}
      input={<BootstrapInput />}
    >
      <MenuItem value={0}>
        {icon && <img src={icon} className="selectbox-icon" />}
        {label}
      </MenuItem>
      {menuList.map((item, index) => (
        <MenuItem value={item.id || index + 1} key={item.id || index}>
          {item?.name || item}
        </MenuItem>
      ))}
    </Select>
    // <FormControl>
    // </FormControl>
  );
}
SelectBox.propTypes = {
  menuList: PropTypes.any,
  label: PropTypes.string,
  customStyle: PropTypes.object,
  customStyleForInput: PropTypes.object,
  onSelect: PropTypes.func,
  value: PropTypes.any,
  icon: PropTypes.any,
};
