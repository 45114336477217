import React, { useEffect } from "react";
import "./Deals.css";
import DealsImage from "assets/img/Deals-image.png";
import CustomButton from "modules/auth/CustomButton/CustomButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import { PropTypes } from "prop-types";

export default function Deals({ getDeals, callInProcess, deals, createDeal }) {
  const [toggleValue, setToggleValue] = React.useState(false);
  const [percentagValue, setPercentageValue] = React.useState(0);

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 36,
      height: 20,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#15C070",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 18,
      height: 18,
      color: "#15C070",
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid #15C070`,
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
      height: "unset",
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  const handleChange = () => {
    const toggle = !toggleValue;
    if (!toggle) {
      setPercentageValue(0);
    } else {
      setPercentageValue(1);
    }
    setToggleValue(toggle);
    console.log(toggle);
  };

  useEffect(() => {
    callInProcess(true);
    getDeals();
  }, []);

  useEffect(() => {
    setToggleValue(deals.dealActive);
    setPercentageValue(deals.dealPercent);
    console.log("ye chal raha hai =====>" + deals);
  }, [deals]);

  const checkNumberInputValue = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const submit = () => {
    callInProcess(true);
    createDeal({ dealActive: toggleValue, dealPercent: percentagValue });
  };

  return (
    <div className="deals-container">
      <span className="deal-title">Manage Deals</span>
      <div className="deal-inner-section">
        <div className="deal-image">
          <img src={DealsImage} />
        </div>
        <div className="deal-content-container">
          <div>
            <span className="deal-status-title">Status</span>
            <div className="deal-toggle-section">
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={toggleValue}
                    onChange={handleChange}
                    name="checkedB"
                  />
                }
              />
              <span className="deal-toggle-status">Active</span>
            </div>
          </div>
          <div className="deal-discount-section">
            <p>Discount Percentage</p>
            <TextField
              className="deal-discount-section-input"
              type="number"
              variant="outlined"
              onChange={(val) => setPercentageValue(val.target.value)}
              size="small"
              onKeyPress={checkNumberInputValue}
              InputProps={{ inputProps: { min: 1 } }}
              disabled={!toggleValue}
              value={percentagValue}
              // value={form.name}
              placeholder="Discount %"
              // onChange={(e) =>
              //   setFieldValues(
              //     "name",
              //     e.target.value,
              //     "",
              //     formLabels,
              //     setFormErrorState,
              //     form,
              //     setForm
              //   )
              // }
            />
          </div>
          <CustomButton title="Save" navigateTo={() => submit()} />
        </div>
      </div>
    </div>
  );
}
Deals.propTypes = {
  getDeals: PropTypes.Func,
  callInProcess: PropTypes.func.isRequired,
  deals: PropTypes.any.isRequired,
  createDeal: PropTypes.Func,
  // showCreateTableModal: PropTypes.boolean,
  // getTables: PropTypes.func.isRequired,
};
