import React, { useEffect } from "react";
import "./Payments.css";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import OrdersModal from "views/OrderDetailsModal";
import SearchIcon from "assets/icons/search-icon.png";
import SelectBox from "components/SelectBox/SelectBox";
import { orderStatus } from "constants/constant";
import PaymentViaCash from "views/PaymentViaCash";
import PaymentViaCredit from "views/PaymentViaCredit";
import PaymentSettings from "views/PaymentSettings";
import { PropTypes } from "prop-types";
import { PaymentMethod } from "constants/payment";
import { orderColumns } from "constants/constant";
function TabPanel(props) {
  // eslint-disable-next-line react/prop-types
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Payments({ getOrderPayment, payments, callInProcess }) {
  const [value, setValue] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [status, setStatus] = React.useState(0);
  const [showModal, setShowModal] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [allPayments, setAllPayments] = React.useState([]);
  const [params, setParams] = React.useState({
    page: 1,
    limit: rowsPerPage,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setParams((val) => ({
      ...val,
      page: newPage + 1,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setParams((val) => ({
      ...val,
      page: 1,
      limit: +event.target.value,
    }));
  };

  const handleChange = (event, newValue) => {
    const queryParams = {
      ...params,
      search: "",
      page: 1,
    };
    if (queryParams.status && event !== "first") {
      delete queryParams.status;
    }
    setPage(0);
    if (event !== "first") {
      setSearch("");
      setStatus(0);
    }
    setValue(newValue);
    if (newValue === 0) {
      callInProcess(true);
      queryParams.id = PaymentMethod.CASH;
      getOrderPayment(queryParams, PaymentMethod.CASH);
    } else if (newValue === 1) {
      callInProcess(true);
      queryParams.id = PaymentMethod.CARD;
      getOrderPayment(queryParams, PaymentMethod.CARD);
    }
    setParams(queryParams);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    handleChange("first", value);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setAllPayments(payments.data);
  }, [payments]);

  const changeStatus = (val) => {
    callInProcess(true);
    setStatus(val);
    const queryParams = {
      ...params,
      page: 1,
      status: val,
    };
    if (val === 0) {
      delete queryParams.status;
    }
    getOrderPayment(
      queryParams,
      value === 0 ? PaymentMethod.CASH : PaymentMethod.CARD
    );
    setParams(queryParams);
  };

  const onSearch = (event) => {
    setSearch(event.target.value);
    const queryParams = {
      ...params,
      search: event.target.value,
    };
    getOrderPayment(
      queryParams,
      value === 0 ? PaymentMethod.CASH : PaymentMethod.CARD
    );
    setParams(queryParams);
  };

  return (
    <div className="payments-container">
      <div className="header-container">
        <div className="header-section">
          <SelectBox
            value={status}
            menuList={orderStatus}
            label="Payment Status"
            onSelect={changeStatus}
            customStyleForInput={{ backgroundColor: "#000", color: "#ffffff" }}
          />
          <TextField
            id="outlined-secondary"
            type="text"
            value={search}
            variant="outlined"
            size="small"
            className="input payment-search-input"
            placeholder="Search"
            InputProps={{
              startAdornment: <img src={SearchIcon} className="search-icon" />,
            }}
            onChange={onSearch}
          />
        </div>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, newValue) => {
            handleChange(event, newValue);
            setPage(0);
          }}
          aria-label="disabled tabs example"
          className="tabs"
        >
          <Tab label="Payments Via Cash" {...a11yProps(0)} />
          <Tab label="Payments Via Credit Card" {...a11yProps(1)} />
          <Tab label="Payments Settings" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <PaymentViaCash
            columns={orderColumns}
            payments={payments}
            page={page}
            rowsPerPage={rowsPerPage}
            allPayments={allPayments}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PaymentViaCredit
            columns={orderColumns}
            payments={payments}
            page={page}
            rowsPerPage={rowsPerPage}
            allPayments={allPayments}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PaymentSettings />
        </TabPanel>
      </div>
      {showModal ? (
        <OrdersModal showModal={showModal} setShowModal={setShowModal} />
      ) : (
        <></>
      )}
    </div>
  );
}
Payments.propTypes = {
  getOrderPayment: PropTypes.func,
  callInProcess: PropTypes.func,
  payments: PropTypes.any,
};
