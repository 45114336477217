import {
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
  GET_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  FAKE_LOGIN,
  REGISTRATION_SUCCESS,
  GET_RESTAURANT_BY_ID_SUCCESS,
  EDIT_RESTAURANT_INFO_SUCCESS,
} from "./auth.actions";
import { RESET_REDUCERS } from "../../../store/core/core.actions";

const INITIAL_STATE = {
  isLoggedIn: false,
  user: {},
  restaurant: {
    subCategories: [],
  },
  token: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      console.log(
        "Action--------------------------------------------------------------",
        action
      );
      return {
        ...state,
        token: action.payload.token,
        isLoggedIn: true,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
      };
    case REGISTRATION_SUCCESS:
      return {
        ...state,
        restaurant: action.payload.restaurant,
        isLoggedIn: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isLoggedIn: true,
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...action.payload.user },
      };
    case FAKE_LOGIN:
      return {
        ...state,
        isLoggedIn: !state.isLoggedIn,
      };
    case RESET_REDUCERS:
      return {
        ...INITIAL_STATE,
      };
    case GET_RESTAURANT_BY_ID_SUCCESS:
      return {
        ...state,
        restaurant: action.payload.restaurant,
      };
    case EDIT_RESTAURANT_INFO_SUCCESS:
      return {
        ...state,
        restaurant: action.payload.restaurant,
      };
    default:
      return state;
  }
};

export default authReducer;
