import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TableCard from "./TableCard";
import { deleteTable } from "modules/table/store/table.actions";

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteTable,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TableCard);
