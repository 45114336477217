import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyAiFnViXmgRZ4c9i7ADMRxSS5Vud4mfwAY",
  authDomain: "cerwiz.firebaseapp.com",
  projectId: "cerwiz",
  storageBucket: "cerwiz.appspot.com",
  messagingSenderId: "507668179039",
  appId: "1:507668179039:web:ab2f9d88ca505b54ea13ea",
  measurementId: "G-RSLGBM0ZX7",
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = (showNotfication) =>
  messaging.onMessage((payload) => {
    showNotfication(payload);
  });
