import React, { useEffect } from "react";
import "./RegistrationSteps.css";
import PropTypes from "prop-types";
import { setFieldValues } from "../../../constants/validation";
import ColorPicker from "components/ColorPicker/ColorPicker";
import Error from "components/Error/Error";
import TextField from "@material-ui/core/TextField";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
// import Input from "@material-ui/core/Input";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import LocationIcon from "assets/icons/location.png";
import FormControl from "@material-ui/core/FormControl";

const RegistrationStep1 = ({
  formLabels,
  form,
  setForm,
  formErrorState,
  setFormErrorState,
  getCategories,
  categories,
}) => {
  const [allCategories, setAllCategories] = React.useState([]);

  useEffect(() => {
    getCategories({ params: { page: 1, limit: 10 } });
  }, []);

  useEffect(() => {
    console.log(categories.data);
    setAllCategories(categories.data);
  }, [categories]);

  const handleChange = (event) => {
    setForm({
      ...form,
      catId: event.target.value,
    });
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  useEffect(() => {
    let google = window.google;
    var input = document.getElementById("searchTextField");
    var autocomplete = new google.maps.places.Autocomplete(input);
    google.maps.event.addListener(autocomplete, "place_changed", () => {
      var place = autocomplete.getPlace();
      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        location: place.name,
      };
      setForm((prev) => ({ ...prev, ...location }));
    });
  }, [setForm]);

  return (
    <div className="content-container">
      <h2>Setting Up Your Business</h2>
      <div className="forms">
        <div className="input-form">
          <p>Business Name</p>
          <TextField
            id="outlined-secondary"
            type="text"
            variant="outlined"
            size="small"
            className="input"
            placeholder="Business Name"
            value={form.name}
            onChange={(e) =>
              setFieldValues(
                "name",
                e.target.value,
                "",
                formLabels,
                setFormErrorState,
                form,
                setForm
              )
            }
          />
          {formErrorState.name && <Error error={formErrorState.name} />}
        </div>
        <div className="input-form">
          <p>Restaurant Categories</p>
          <FormControl className="input">
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              displayEmpty
              multiple
              value={form.catId}
              onChange={handleChange}
              input={<BootstrapInput />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <span>Select Category</span>;
                }
                return selected.map((cat) => cat.name).join(", ");
              }}
              MenuProps={MenuProps}
            >
              <MenuItem disabled value="">
                <span>Select Category</span>
              </MenuItem>
              {allCategories &&
                allCategories?.map((category, index) => (
                  <MenuItem key={index} value={category}>
                    <Checkbox
                      checked={
                        form.catId.findIndex((cat) => cat.id === category.id) >
                        -1
                      }
                      onClick={(event) => event.preventDefault()}
                      style={{
                        color: "#15C070",
                      }}
                    />
                    <ListItemText primary={category.name} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {formErrorState.catId && <Error error={formErrorState.catId} />}
        </div>
        <div className="input-form">
          <p>Contact Number</p>
          <TextField
            id="outlined-secondary"
            type="text"
            variant="outlined"
            size="small"
            className="input"
            placeholder="Contact Number"
            value={form.contactNo}
            onChange={(e) =>
              setFieldValues(
                "contactNo",
                e.target.value,
                "",
                formLabels,
                setFormErrorState,
                form,
                setForm
              )
            }
          />
          {formErrorState.contactNo && (
            <Error error={formErrorState.contactNo} />
          )}
        </div>
        <div className="input-form">
          <p>Restaurants Timing</p>
          <div className="select-box-container">
            <div className="column-field">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justifyContent="space-around">
                  <KeyboardTimePicker
                    margin="normal"
                    id="open-time"
                    className="time"
                    label="Opening Time"
                    value={form.openTime}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                    onChange={(val) =>
                      setFieldValues(
                        "openTime",
                        val,
                        "",
                        formLabels,
                        setFormErrorState,
                        form,
                        setForm
                      )
                    }
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              {formErrorState.openTime && (
                <Error error={formErrorState.openTime} />
              )}
            </div>
            <div className="column-field">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justifyContent="space-around">
                  <KeyboardTimePicker
                    margin="normal"
                    id="close-time"
                    className="time"
                    label="Closing Time"
                    value={form.closeTime}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(val) =>
                      setFieldValues(
                        "closeTime",
                        val,
                        "",
                        formLabels,
                        setFormErrorState,
                        form,
                        setForm
                      )
                    }
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              {formErrorState.closeTime && (
                <Error error={formErrorState.closeTime} />
              )}
            </div>
          </div>
        </div>
        <div className="input-form">
          <p>Restaurant`s Address</p>
          <TextField
            id="searchTextField"
            type="text"
            variant="outlined"
            size="small"
            className="input"
            autoComplete="on"
            value={form.location}
            placeholder="Restaurant`s Address"
            InputProps={{
              endAdornment: (
                <img src={LocationIcon} className="eye-crossed-icon" />
              ),
            }}
            onChange={(e) =>
              setFieldValues(
                "location",
                e.target.value,
                "",
                formLabels,
                setFormErrorState,
                form,
                setForm
              )
            }
          />
          {formErrorState.location && <Error error={formErrorState.location} />}
        </div>
        <div className="input-form">
          <p>Chooose Theme Color</p>
          <ColorPicker
            customStyle={{ height: "50px" }}
            onSelect={(val) =>
              setFieldValues(
                "themeColor",
                val,
                "",
                formLabels,
                setFormErrorState,
                form,
                setForm
              )
            }
          />
          {formErrorState.themeColor && (
            <Error error={formErrorState.themeColor} />
          )}
        </div>
      </div>
    </div>
  );
};

RegistrationStep1.propTypes = {
  formLabels: PropTypes.object,
  form: PropTypes.object,
  setForm: PropTypes.func,
  formErrorState: PropTypes.object,
  setFormErrorState: PropTypes.func,
  getCategories: PropTypes.func,
  categories: PropTypes.any,
};
export default RegistrationStep1;
