import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Menu from "./Menu";
// import { getMenus, getTabs, getCategories } from "./../store/menu.actions";
// import { getMenus, getTabs } from "./../store/menu.actions";
import { getRestaurantById } from "../../auth/store/auth.actions";
import { callInProcess } from "store/core/core.actions";
import { deleteTab } from "../store/menu.actions";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // getMenus,
      // getCategories,
      // getTabs,
      callInProcess,
      getRestaurantById,
      deleteTab,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  const { auth } = state;
  console.log("auth --->>>", auth);
  return {
    restaurant: auth.restaurant,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
