import { combineEpics, select } from "redux-most";
import * as most from "most";
import { callInProcess } from "store/core/core.actions";
// import { createTable } from "core/services/table/table.service";
import { CREATE_DEAL, getDealsSuccess, GET_DEALS } from "./deals.actions";
import { getDeals } from "core/services/deals/deals.service";
import { createDeal } from "core/services/deals/deals.service";
import { showToast } from "store/toast/toast.actions";
// import { getTableById } from "core/services/table/table.service";

const getDealsEpic = ($actions) =>
  $actions.thru(select(GET_DEALS)).flatMap(() => {
    return most
      .fromPromise(getDeals())
      .flatMap((response) => {
        console.log(response);
        return most.from([
          getDealsSuccess({
            deals: response,
          }),
          callInProcess(false),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const createDealEpic = ($actions) =>
  $actions.thru(select(CREATE_DEAL)).flatMap((action) => {
    return most
      .fromPromise(createDeal(action.payload))
      .flatMap((response) => {
        console.log(response);
        return most.from([
          callInProcess(false),
          showToast({
            message: "Deal Updated Successfully",
            type: "success",
          }),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

export default combineEpics([getDealsEpic, createDealEpic]);
