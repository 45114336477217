import React, { useState, useEffect } from "react";
import "./CreateMenuModal.css";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
// import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ImageUploding from "assets/img/Vector.png";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import CustomButton from "../../modules/auth/CustomButton/CustomButton";
import { PropTypes } from "prop-types";
// import SelectBox from "components/SelectBox/SelectBox";
import ImageUploading from "react-images-uploading";
import { setFieldValues } from "../../constants/validation";
import Error from "../../components/Error/Error";
import SelectBox from "components/SelectBox";
import { checkFormValid } from "constants/validation";
// import { uploadFile } from "react-s3";
// import { config } from "constants/config-constant";
import { toast } from "react-toastify";
import DeleteImage from "assets/icons/delete.png";
import axios from "axios";
import { apiBaseUrl } from "constants/api-baseUrl";

const styles = (theme) => ({
  root: {
    margin: 0,
    borderBottom: "none",
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: "25px",
    top: "25px",
    padding: "0px",
    width: "16px",
    height: "16px",
    color: theme.palette.grey[500],
    "&:hover": {
      background: "transparent",
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose } = props;
  return (
    // <MuiDialogTitle disableTypography className={classes.root} {...other}>
    //   {onClose ? (
    //   ) : null}
    // </MuiDialogTitle>
    <IconButton
      aria-label="close"
      className={classes.closeButton}
      onClick={onClose}
    >
      <CloseIcon />
    </IconButton>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CreateMenuModal({
  showCreateMenuModal,
  setShowCreateMenuModal,
  categories = [],
  createMenu,
  editMenu,
  callInProcess,
  menus,
  // getRestaurantById,
}) {
  const formLabels = {
    name: "Name",
    description: "Description",
    price: "Price",
    subCatId: "Category",
    img: "Image",
  };
  const [form, setForm] = useState({
    name: "",
    description: "",
    price: 1,
    subCatId: 0,
    img: "",
    addons: [],
  });
  const [formErrorState, setFormErrorState] = useState({
    name: "",
    description: "",
    price: "",
    subCatId: "",
    img: "",
    addons: "",
  });

  const uploadImage = (field, imageList) => {
    setFieldValues(
      field,
      imageList[0],
      "",
      formLabels,
      setFormErrorState,
      form,
      setForm
    );
    setFormErrorState({
      ...formErrorState,
      [field]: "",
    });
  };

  useEffect(() => {
    console.log("Categories-------------------", categories);
  }, [categories]);

  useEffect(() => {
    if (showCreateMenuModal?.id) {
      const subCatIndex = categories.findIndex(
        (category) => category.id === showCreateMenuModal.subCatId
      );
      showCreateMenuModal = {
        ...showCreateMenuModal,
        subCatId: subCatIndex + 1,
      };
      setForm({ ...form, ...showCreateMenuModal });
    }
  }, [showCreateMenuModal]);

  const onChangeAddons = (e, index, field, label) => {
    const tempForm = { ...form };
    tempForm.addons[index][field] = e.target.value;
    tempForm.addons[index][field + "Error"] = !e.target.value
      ? label + " is required"
      : "";
    setForm({ ...tempForm });
  };

  const handleUpload = async (file, type) => {
    const formData = new FormData();
    formData.append("cvc", file, "image.jpg");

    let doc = await axios.post(`${apiBaseUrl}fileupload`, formData);

    console.log(
      "uploadMediaResponse-----------------------------------------======================",
      doc
    );

    uploadImage(type, [doc.data.url]);
    callInProcess(false);
    toast.success("Upload Successfully");
    // uploadFile(file, config)
    //   .then((data) => {
    //     uploadImage(type, [data.location]);
    //     toast.success("Upload Successfully");
    //   })
    //   .catch((err) => console.error(err));
  };

  const closeModal = () => setShowCreateMenuModal(false);

  const submit = () => {
    console.log("InsideOnSubmitFunction");
    let leftFormValid = checkFormValid(
      form,
      formErrorState,
      setFormErrorState,
      formLabels
    );
    let isValid = true;
    let tempForm = { ...form };
    leftFormValid = true;
    if (!leftFormValid) {
      return;
    }
    if (leftFormValid) {
      const selectedTab = menus.find(
        (menu) => menu.id === categories[tempForm.subCatId - 1].id
      );
      const isMenuCreated = selectedTab?.items.find((menu) => {
        if (menu.name === form.name) {
          if (menu.id === showCreateMenuModal?.id) {
            console.log("InsideIfCondition=====================");
            return false;
          } else {
            console.log("InsideElseCondtion======================");
            return true;
          }
        }
      });
      if (isMenuCreated) {
        toast.error("Menu name already exists");
        return;
      }
    }
    if (leftFormValid) {
      for (let i = 0; i < tempForm.addons.length; i++) {
        for (let [key, value] of Object.entries(tempForm.addons[i])) {
          if (key.indexOf("Error") === -1) {
            tempForm.addons[i][key + "Error"] = !value
              ? key + " is required"
              : "";
            if (!value && isValid) {
              isValid = false;
            }
          }
        }
      }
      setForm({ ...tempForm });
    }
    if (leftFormValid && isValid) {
      tempForm = {
        ...tempForm,
        subCatId: categories[tempForm.subCatId - 1].id,
        // subCatId: 1,
        price: Number(tempForm.price),
        addons: tempForm.addons.map((item) => ({
          name: item.name,
          price: Number(item.price),
        })),
      };
      console.log("Submit", tempForm);
      callInProcess(true);
      if (showCreateMenuModal?.id) {
        delete tempForm.id;
        editMenu(tempForm, showCreateMenuModal.id);
      } else {
        createMenu(tempForm);
      }
      closeModal();
    }
  };

  const removeAddOn = (index) => {
    const updatedAddons = [...form.addons];
    updatedAddons.splice(index, 1);
    const newForm = { ...form, addons: updatedAddons };
    console.log(newForm);
    setForm(newForm);
  };

  const checkNumberInputValue = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const title = showCreateMenuModal?.id ? "Edit" : "Create";

  const useStyles = makeStyles(() => ({
    backDrop: {
      backdropFilter: "blur(3px)",
      backgroundColor: "rgba(50,50,30,0.1)",
    },
  }));

  const classes = useStyles();

  return (
    <Dialog
      onClose={() => setShowCreateMenuModal(false)}
      aria-labelledby="customized-dialog-title"
      open={showCreateMenuModal}
      fullWidth="md"
      maxWidth="md"
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => setShowCreateMenuModal(false)}
      ></DialogTitle>
      <DialogContent>
        <div className="dailog-container">
          <div className="dailog-left-container">
            <div className="sectionA">
              <h3 className="main-title">{title} menu</h3>
            </div>
            <div className="sectionB">
              <div className="create-menu-sectionB-item">
                <ImageUploading
                  onChange={(imageList) =>
                    handleUpload(imageList[0].file, "img")
                  }
                  maxNumber={1}
                  dataURLKey="data_url"
                >
                  {({ onImageUpload }) => (
                    <div
                      onClick={onImageUpload}
                      className="create-menu-modal-image-uploading"
                    >
                      {form.img ? (
                        <img src={form.img} className="selected-image" />
                      ) : (
                        <img src={ImageUploding} />
                      )}
                    </div>
                  )}
                </ImageUploading>
                {formErrorState.img && <Error error={formErrorState.img} />}
                <div className="create-menu-modal-input-sectionA">
                  <p className="create-menu-modal-input-label">Menu Name</p>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className="create-menu-modal-input"
                  >
                    <TextField
                      id="outlined-adornment-password"
                      type="text"
                      variant="outlined"
                      size="small"
                      pattern="[0-9]*"
                      value={form.name}
                      placeholder="Enter Menu Name"
                      onChange={(e) =>
                        setFieldValues(
                          "name",
                          e.target.value,
                          "",
                          formLabels,
                          setFormErrorState,
                          form,
                          setForm
                        )
                      }
                    />
                    {formErrorState.name && (
                      <Error error={formErrorState.name} />
                    )}
                  </FormControl>
                </div>
                <div className="create-menu-modal-input-sectionB">
                  <div className="create-menu-modal-input-sectionB-inner-section">
                    <p className="create-menu-modal-input-label">Price</p>
                    <FormControl
                      size="small"
                      variant="outlined"
                      className="create-menu-modal-input"
                    >
                      <TextField
                        id="outlined-adornment-password"
                        type="number"
                        variant="outlined"
                        size="small"
                        value={form.price}
                        placeholder="Price $$$"
                        InputProps={{ inputProps: { min: 1 } }}
                        onKeyPress={checkNumberInputValue}
                        onChange={(e) =>
                          setFieldValues(
                            "price",
                            e.target.value,
                            "",
                            formLabels,
                            setFormErrorState,
                            form,
                            setForm
                          )
                        }
                      />
                      {formErrorState.price && (
                        <Error error={formErrorState.price} />
                      )}
                    </FormControl>
                  </div>
                  <div className="create-menu-modal-input-sectionB-inner-section">
                    <p className="create-menu-modal-input-label">
                      Menu Category
                    </p>
                    <FormControl
                      size="small"
                      variant="outlined"
                      className="create-menu-modal-input"
                    >
                      <SelectBox
                        menuList={categories?.map((cat) => cat.name)}
                        label="Choose"
                        customStyle={{ minWidth: "0px" }}
                        value={form.subCatId}
                        onSelect={(val) => {
                          setFieldValues(
                            "subCatId",
                            val,
                            "",
                            formLabels,
                            setFormErrorState,
                            form,
                            setForm
                          );
                        }}
                      />
                      {/* {formErrorState.subCatId && (
                        <Error error={formErrorState.subCatId} />
                      )} */}
                    </FormControl>
                  </div>
                </div>
                <div className="create-menu-modal-input-sectionC">
                  <p className="create-menu-modal-input-label">
                    Menu Description
                  </p>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className="create-menu-modal-input"
                  >
                    <TextField
                      id="outlined-multiline-static"
                      label="Add Description"
                      multiline
                      rows={3}
                      inputProps={{ maxLength: 500 }}
                      variant="outlined"
                      value={form.description}
                      onChange={(e) =>
                        setFieldValues(
                          "description",
                          e.target.value,
                          "",
                          formLabels,
                          setFormErrorState,
                          form,
                          setForm
                        )
                      }
                    />
                    {formErrorState.description && (
                      <Error error={formErrorState.description} />
                    )}
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
          <div className="dailog-right-container">
            <div className="sectionA">
              <h3 className="main-title">Add Ons</h3>
            </div>
            <div className="create-menu-right-section">
              <div className="create-menu-right-first-section">
                {form.addons.map((adon, index) => (
                  <div key={index} className="addons-row">
                    <div className="create-menu-right-first-inner-section1">
                      <p className="create-menu-modal-input-label">
                        Addon Name
                      </p>
                      <TextField
                        id="outlined-adornment-password"
                        type="text"
                        variant="outlined"
                        size="small"
                        placeholder="Name"
                        className="create-menu-modal-input"
                        value={adon.name}
                        onChange={(e) =>
                          onChangeAddons(e, index, "name", "Name")
                        }
                      />
                      {adon.nameError && <Error error={adon.nameError} />}
                    </div>
                    <div className="create-menu-right-first-inner-section2">
                      <p className="create-menu-modal-input-label">Price</p>
                      <TextField
                        id="outlined-adornment-password"
                        type="number"
                        variant="outlined"
                        size="small"
                        placeholder="$$$"
                        className="create-menu-modal-input"
                        value={adon.price}
                        onKeyPress={checkNumberInputValue}
                        InputProps={{ inputProps: { min: 1 } }}
                        onChange={(e) =>
                          onChangeAddons(e, index, "price", "Price")
                        }
                      />
                      {adon.priceError && <Error error={adon.priceError} />}
                    </div>
                    <div className="create-menu-right-first-inner-trash">
                      <img
                        className="create-menu-trash-img"
                        src={DeleteImage}
                        onClick={() => removeAddOn(index)}
                      />
                    </div>
                  </div>
                ))}
                <div className="create-menu-right-first-inner-section3">
                  <button
                    className="create-menu-right-first-inner-section3-button"
                    onClick={() => {
                      setForm({
                        ...form,
                        addons: [
                          ...form.addons,
                          {
                            price: 1,
                            name: "",
                          },
                        ],
                      });
                    }}
                  >
                    Add More Add Ons
                  </button>
                </div>
              </div>
              <div className="create-menu-right-second-section">
                <CustomButton
                  title="Cancel"
                  customStyles={{
                    width: "98px",
                    backgroundColor: "#ffffff",
                    color: "#42526E",
                    border: "1px solid rgba(66, 82, 110, 0.7)",
                    marginRight: "20px",
                  }}
                  navigateTo={closeModal}
                />
                <CustomButton
                  title={showCreateMenuModal?.id ? "Update" : "Create"}
                  customStyles={{ width: "131px" }}
                  navigateTo={() => submit()}
                />
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
CreateMenuModal.propTypes = {
  showCreateMenuModal: PropTypes.boolean,
  setShowCreateMenuModal: PropTypes.Func,
  categories: PropTypes.any,
  createMenu: PropTypes.func.isRequired,
  editMenu: PropTypes.func,
  callInProcess: PropTypes.func.isRequired,
  menus: PropTypes.array,
};
