import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MessageIcon from "assets/icons/message-icon.png";
import logoutIcon from "assets/icons/logout-2432055-2058929.png";
import UploadingImage from "assets/img/fi-rr-upload.png";
import BellIcon from "assets/icons/bell-icon.png";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { PropTypes } from "prop-types";
import "./AdminNavbarLinks.css";
import Message from "components/Messages/Messages";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks({
  logOut,
  callInProcess,
  profileIcon,
}) {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const classes = useStyles();
  // const [openNotification, setOpenNotification] = React.useState(null);
  // const [openProfile, setOpenProfile] = React.useState(null);
  // const handleClickNotification = (event) => {
  //   if (openNotification && openNotification.contains(event.target)) {
  //     setOpenNotification(null);
  //   } else {
  //     setOpenNotification(event.currentTarget);
  //   }
  // };
  // const handleCloseNotification = () => {
  //   setOpenNotification(null);
  // };
  // const handleClickProfile = (event) => {
  //   if (openProfile && openProfile.contains(event.target)) {
  //     setOpenProfile(null);
  //   } else {
  //     setOpenProfile(event.currentTarget);
  //   }
  // };
  // const handleCloseProfile = () => {
  //   setOpenProfile(null);
  // };

  const logout = () => {
    callInProcess(true);
    logOut();
  };

  return (
    <div className={classes.navRightSection}>
      <div className={classes.icons} onClick={logout}>
        <img src={logoutIcon} className={classes.icon} />
      </div>
      <div className={classes.icons}>
        <img
          src={MessageIcon}
          className={classes.icon}
          onClick={() => setOpenDrawer(true)}
        />
      </div>
      <div className={classes.icons}>
        <img src={BellIcon} className={classes.icon} />
      </div>
      <div className={classes.icons}>
        <img
          src={profileIcon ? profileIcon : UploadingImage}
          className="profileIconImage"
        />
      </div>
      <Message openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
    </div>
  );
}
AdminNavbarLinks.propTypes = {
  logOut: PropTypes.func.isRequired,
  callInProcess: PropTypes.func.isRequired,
  profileIcon: PropTypes.any,
  user: PropTypes.object,
};
