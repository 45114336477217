export const ServerResponseMiddleware = () => {
  return () => ({
    response(next) {
      return next().then((res) => {
        console.log("server response middleware", res.responseData);
        return JSON.parse(res.responseData);
      });
    },
  });
};
