import AuthenticationTokenHelper from "core/helper/AuthenticationTokenHelper";
import { ServerErrorService } from "../services/server-errors/server-errors.service";

export const ServerErrorMiddleware = () => {
  return () => ({
    response(next) {
      return next().catch((res) => {
        console.log("Error ", res);
        const response = res.data();
        if (response.code !== 200) {
          ServerErrorService(response);
        }
        if (response.code === 401) {
          localStorage.removeItem("cerwiz-token");
          localStorage.removeItem("user");
          AuthenticationTokenHelper.authToken = null;
          window.appHistory.push("/login");
        }
        return next();
      });
    },
  });
};
