import {
  CREATE_MENU_SUCCESS,
  CREATE_TAB_SUCCESS,
  GET_CATEGORIES_SUCCESS,
  GET_MENUS_SUCCESS,
  GET_MENU_DETAILS_SUCCESS,
  GET_TABS_SUCCESS,
} from "./menu.actions";

const INITIAL_STATE = {
  menus: {
    data: [],
  },
  categories: {
    data: [],
  },
  recentMenus: {
    data: [],
  },
  menuDetails: {},
  tabs: {
    data: [],
  },
};

const menuReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MENUS_SUCCESS:
      return {
        ...state,
        [action.payload.type]: action.payload.menus,
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories,
      };
    case GET_MENU_DETAILS_SUCCESS:
      return {
        ...state,
        menuDetails: action.payload.menuDetails,
      };
    case GET_TABS_SUCCESS:
      return {
        ...state,
        tabs: action.payload.tabs,
      };
    case CREATE_TAB_SUCCESS:
      return {
        ...state,
        tabs: {
          ...state.tabs,
          data: [action.payload.tab, ...state.tabs.data],
        },
      };
    case CREATE_MENU_SUCCESS:
      return {
        ...state,
        menus: [action.payload.menu, ...state.menus.data],
      };
    default:
      return state;
  }
};

export default menuReducer;
