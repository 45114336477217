export const checkForRequiredField = (field, value, formLabels) => {
  const isInvalid = Array.isArray(value)
    ? !value.length
    : typeof value !== "string"
    ? !value
    : !value;
  return isInvalid ? `${formLabels[field]} is required` : "";
};

export const checkFormValid = (
  formValue,
  errorState,
  setErrorState,
  formLabels
) => {
  let isValid = true;
  const errorForm = { ...errorState };
  for (const [key, value] of Object.entries(errorForm)) {
    const isObject = typeof value === "object";
    if (isObject) {
      for (const [subKey, subValue] of Object.entries(value)) {
        const isValidForm = isValidProperty(
          formValue[key][subKey],
          subKey,
          formLabels,
          errorForm,
          subValue,
          key
        );
        if (!isValidForm) {
          isValid = false;
        }
      }
    } else {
      const isValidForm = isValidProperty(
        formValue[key],
        key,
        formLabels,
        errorForm,
        value,
        ""
      );
      if (!isValidForm) {
        isValid = false;
      }
    }
  }
  setErrorState((prev) => ({
    ...prev,
    ...errorForm,
  }));
  return isValid;
};

const isValidProperty = (
  value,
  key,
  formLabels,
  errorForm,
  errorValue,
  parentKey
) => {
  const isInvalid = Array.isArray(value) ? !value?.length : !value;
  const alreadyHasKey = parentKey
    ? formLabels[parentKey][key]
    : formLabels[key];
  if (isInvalid && alreadyHasKey) {
    if (parentKey) {
      errorForm[parentKey][key] =
        errorForm[parentKey][key] ||
        checkForRequiredField(key, errorValue, formLabels[parentKey]);
    } else {
      errorForm[key] =
        errorForm[key] || checkForRequiredField(key, errorValue, formLabels);
    }
    return false;
  }
  return true;
};

export const setFieldValues = (
  field,
  value,
  parentType,
  formLabels,
  setFormErrorState,
  formData,
  updateData
) => {
  const errMessage = checkForRequiredField(field, value, formLabels);

  setFormErrorState((prev) => {
    const errors = { ...prev };
    if (parentType) {
      errors[parentType][field] = errMessage;
    } else {
      errors[field] = errMessage;
    }
    return errors;
  });
  let updatedData = {
    ...formData,
  };
  updatedData = {
    ...updatedData,
    [field]: value,
  };
  if (updateData) updateData(updatedData);
};
