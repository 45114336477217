import React, { useState } from "react";
import "./RegistrationSteps.css";
import "../Auth.css";
import RegistrationStep1 from "./RegistrationStep1";
import RegistrationStep2 from "./RegistrationStep2";
import RegistrationStep3 from "./RegistrationStep3";
import VerticalStepper from "./VerticalStepper";
import { checkFormValid } from "constants/validation";
import { PropTypes } from "prop-types";

const RegistrationSteps = ({
  callInProcess,
  registration,
  getCategories,
  categories,
}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ["Info", "Profile Pics", "Location"];
  const formLabelsStep1 = {
    name: "Name",
    location: "Location",
    openTime: "Open Time",
    closeTime: "Close Time",
    catId: "Category",
    contactNo: "Contact No",
    themeColor: "Theme Color",
  };
  const [formStep1, setFormStep1] = useState({
    name: "",
    location: "",
    lat: 0,
    lng: 0,
    rating: 0,
    openTime: new Date(),
    closeTime: new Date(),
    catId: [],
    coverImg: "",
    logo: "",
    contactNo: "",
    themeColor: "",
  });
  const [formErrorStateStep1, setFormErrorStateStep1] = useState({
    name: "",
    location: "",
    openTime: "",
    closeTime: "",
    catId: [],
    coverImg: "",
    logo: "",
    contactNo: "",
    themeColor: "",
  });
  const formLabelsStep2 = {
    logo: "Restaurant Logo",
    coverImg: "Restaurant Cover",
  };
  const [formStep2, setFormStep2] = useState({
    logo: "",
    coverImg: "",
  });
  const [formErrorStateStep2, setFormErrorStateStep2] = useState({
    logo: "",
    coverImg: "",
  });

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleNext = () => {
    console.log("InsideHandleNext");
    if (activeStep === 0) {
      const isFormOneInvalid = !checkFormValid(
        formStep1,
        formErrorStateStep1,
        setFormErrorStateStep1,
        formLabelsStep1
      );
      if (isFormOneInvalid) {
        console.log("postData====>>");
        return;
      }
      // if (!formStep1.location || !formStep1.lat || !formStep1.lng) {
      //   console.log("LocationIssuesCondition");
      //   return;
      // }
    } else if (activeStep === 1) {
      console.log("InsideactiveStep1------------------");
      if (
        !checkFormValid(
          formStep2,
          formErrorStateStep2,
          setFormErrorStateStep2,
          formLabelsStep2
        )
      ) {
        return;
      }
    }
    if (activeStep === 2) {
      console.log("InsideactiveStep2------------------");
      callInProcess(true);
      const postData = { ...formStep1, ...formStep2 };
      postData.catId = postData.catId.map((cat) => cat.id);
      postData.openTime = postData.openTime.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      postData.closeTime = postData.closeTime.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      postData.rating = 0;
      console.log("postData====>>", postData);
      registration(postData);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const getLocation = (location) => {
    console.log(location);
    setFormStep1((prev) => ({ ...prev, ...location }));
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <RegistrationStep1
            formLabels={formLabelsStep1}
            form={formStep1}
            setForm={setFormStep1}
            formErrorState={formErrorStateStep1}
            setFormErrorState={setFormErrorStateStep1}
            getCategories={getCategories}
            categories={categories}
          />
        );
      case 1:
        return (
          <RegistrationStep2
            formLabels={formLabelsStep2}
            form={formStep2}
            setForm={setFormStep2}
            formErrorState={formErrorStateStep2}
            setFormErrorState={setFormErrorStateStep2}
            callInProcess={callInProcess}
          />
        );
      case 2:
        return (
          <RegistrationStep3
            selectedLat={formStep1.lat}
            selectedLng={formStep1.lng}
            restaurantName={formStep1.name}
            getLocation={getLocation}
          />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <VerticalStepper
      activeStep={activeStep}
      handleStep={handleStep}
      steps={steps}
      getStepContent={getStepContent}
      handleBack={handleBack}
      handleNext={handleNext}
    />
  );
};

RegistrationSteps.propTypes = {
  registration: PropTypes.func.isRequired,
  callInProcess: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  categories: PropTypes.any,
};

export default RegistrationSteps;
