import React, { useRef } from "react";
import "./ShowQRCodeModal.css";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
// import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { PropTypes } from "prop-types";
import QRCodeImage from "assets/img/QR-code.png";
// import QRCode from "qrcode.react";
import { savePDF } from "@progress/kendo-react-pdf";

const styles = (theme) => ({
  root: {
    margin: 0,
    borderBottom: "none",
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: "25px",
    top: "25px",
    padding: "0px",
    width: "16px",
    height: "16px",
    color: theme.palette.grey[500],
    "&:hover": {
      background: "transparent",
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose } = props;
  return (
    // <MuiDialogTitle disableTypography className={classes.root} {...other}>
    //   {onClose ? (
    //   ) : null}
    // </MuiDialogTitle>
    <IconButton
      aria-label="close"
      className={classes.closeButton}
      onClick={onClose}
    >
      <CloseIcon />
    </IconButton>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CreateNewTabModal({ selectedTable, setSelectedTable }) {
  const contentArea = useRef(null);
  const closeModal = () => {
    setSelectedTable(null);
  };
  const downloadPDF = () => {
    savePDF(contentArea.current, { paperSize: "A4" });
  };
  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={selectedTable}
      fullWidth="sm"
      maxWidth="sm"
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={closeModal}
      ></DialogTitle>
      <DialogContent>
        <div ref={contentArea}>
          <div className="qr-code-modal-container">
            <div className="noExport">
              <h3 className="create-table-qr-title">QR Code Card</h3>
            </div>
            <div className="qr-code-image">
              <div className="qr-table-no-section">
                <p className="qr-table-no">Table No: {selectedTable.id}</p>
              </div>
              <img src={QRCodeImage} />
              <div className="qr-code">
                {/* <QRCode size="92" value="www.cerwiz.com/12345/" /> */}
              </div>
            </div>
            <div className="create-table-download-pdf noExport">
              <span onClick={downloadPDF}>Download PDF Now!</span>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
CreateNewTabModal.propTypes = {
  selectedTable: PropTypes.boolean,
  setSelectedTable: PropTypes.func,
  callInProcess: PropTypes.func.isRequired,
  createTab: PropTypes.func.isRequired,
};
