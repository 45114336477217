import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Payments from "./Payments";
import { getOrderPayment } from "modules/order/store/order.actions";
import { callInProcess } from "store/core/core.actions";

const mapStateToProps = (state) => {
  const { order } = state;
  return {
    payments: order.payments,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      callInProcess,
      getOrderPayment,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
