import React, { useState, useEffect } from "react";
import "./RestaurantInformation.css";
import VectorImage from "assets/img/Vector.png";
import UploadingImage from "assets/img/fi-rr-upload.png";
import LocationIcon from "assets/icons/location-green-icon.png";
import EyeCrossedIcon from "assets/icons/eye-crossed-icon.png";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import ColorPicker from "components/ColorPicker/ColorPicker";
import { setFieldValues } from "constants/validation";
import Error from "components/Error/Error";
import ImageUploading from "react-images-uploading";
import CustomButton from "modules/auth/CustomButton/CustomButton";
import { PropTypes } from "prop-types";
import { checkFormValid } from "constants/validation";
import { QRCode } from "react-qrcode-logo";
import { uploadFile } from "react-s3";
import { config } from "constants/config-constant";
import { toast } from "react-toastify";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import ListItemText from "@material-ui/core/ListItemText";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import { getMonthNameAndYear } from "../../constants/dateTime";

const maxNumber = 1;

export default function RestaurantInformation({
  editRestaurantInfo,
  getRestaurantById,
  callInProcess,
  restaurant,
  getCategories,
  categories,
}) {
  const formLabels = {
    name: "Name",
    location: "Location",
    openTime: "Open Time",
    closeTime: "Close Time",
    catId: "Category",
    contactNo: "Contact No",
    themeColor: "Theme Color",
    logo: "Restaurant Logo",
    coverImg: "Restaurant Cover",
  };
  let [form, setForm] = useState({
    name: "",
    rating: 0,
    img: "empty",
    openTime: "",
    closeTime: "",
    catId: [],
    coverImg: "",
    logo: "",
    contactNo: "",
    themeColor: "",
    lat: 0,
    lng: 0,
  });
  const [formError, setFormError] = useState({
    name: "",
    location: "",
    openTime: "",
    closeTime: "",
    catId: [],
    coverImg: "",
    logo: "",
    contactNo: "",
    themeColor: "",
  });

  const [allCategories, setAllCategories] = React.useState([]);

  const uploadImage = (field, imageList) => {
    setFieldValues(
      field,
      imageList[0],
      "",
      formLabels,
      setFormError,
      form,
      setForm
    );
    setFormError({
      ...formError,
      [field]: "",
    });
  };

  const editRestaurantInformation = () => {
    if (!checkFormValid(form, formError, setFormError, formLabels)) {
      return;
    } else {
      // form = {
      //   ...form,
      //   openTime: form.openTime.toString(),
      //   img: "",
      //   closeTime: form.closeTime.toString(),
      //   catId: [form.catId.toString()],
      //   coverImg: "",
      //   logo: "",
      // };
      callInProcess(true);
      const data = {
        name: form.name,
        location: form.location,
        lat: form.lat,
        lng: form.lng,
        rating: form.rating,
        logo: form.logo,
        coverImg: form.coverImg,
        openTime: form.openTime.toString(),
        closeTime: form.closeTime.toString(),
        catId: form.catId.map((cat) => cat.id),
        contactNo: form.contactNo,
        themeColor: form.themeColor,
      };
      data.openTime = new Date(data.openTime).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      data.closeTime = new Date(data.closeTime).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      editRestaurantInfo(data);
    }
  };

  useEffect(() => {
    callInProcess(true);
    getRestaurantById();
    getCategories({ params: { page: 1, limit: 10 } });
  }, []);

  useEffect(() => {
    const res = restaurant;
    const { year, monthDecimal, day } = getMonthNameAndYear(new Date());
    if (!res.openTime) {
      res.openTime = new Date();
    }
    if (!res.closeTime) {
      res.closeTime = new Date();
    }
    if (typeof res.openTime === "object" || res.openTime.includes("GMT")) {
      res.openTime = new Date(res.openTime).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    }
    if (typeof res.closeTime === "object" || res.closeTime.includes("GMT")) {
      res.closeTime = new Date(res.closeTime).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    }
    const openTime = convertTime12To24(res.openTime || "12:00 PM").split(":");
    const closeTime = convertTime12To24(res.closeTime || "12:00 AM").split(":");
    res.openTime = new Date(year, monthDecimal, day, openTime[0], openTime[1]);
    res.closeTime = new Date(
      year,
      monthDecimal,
      day,
      closeTime[0],
      closeTime[1]
    );
    setForm({
      ...form,
      ...res,
    });
    console.log("theme color" + res.themeColor);
  }, [restaurant]);

  useEffect(() => {
    setAllCategories(categories.data);
  }, [categories]);

  const convertTime12To24 = (time) => {
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM === "PM" && hours < 12) hours = hours + 12;
    if (AMPM === "AM" && hours === 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes;
  };

  const handleUpload = async (file, type) => {
    callInProcess(true);
    uploadFile(file, config)
      .then((data) => {
        console.log(data);
        uploadImage(type, [data.location]);
        callInProcess(false);
        toast.success("Upload Successfully");
      })
      .catch((err) => console.error(err));
  };

  const handleChange = (event) => {
    setForm({
      ...form,
      catId: event.target.value,
    });
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  return (
    <div className="settings-container">
      <div className="settings-container-left-section">
        <h3 className="restaurant-titles">Restaurant Basic Info</h3>
        <div className="settings-container-input-section">
          <span className="settings-container-input-label">Business Name</span>
          <FormControl
            size="small"
            variant="outlined"
            className="settings-container-input"
          >
            <TextField
              id="outlined-adornment-password"
              type="text"
              variant="outlined"
              size="small"
              placeholder="Enter Business Name"
              value={form.name}
              onChange={(e) =>
                setFieldValues(
                  "name",
                  e.target.value,
                  "",
                  formLabels,
                  setFormError,
                  form,
                  setForm
                )
              }
            />
            {formError.name && <Error error={formError.name} />}
          </FormControl>
        </div>
        <div className="settings-container-input-section">
          <span className="settings-container-input-label">Contact Number</span>
          <FormControl
            size="small"
            variant="outlined"
            className="settings-container-input"
          >
            <TextField
              id="outlined-adornment-password"
              type="text"
              variant="outlined"
              size="small"
              placeholder="+1 234 567 890"
              InputProps={{
                endAdornment: (
                  <img src={EyeCrossedIcon} className="eye-crossed-icon" />
                ),
              }}
              value={form.contactNo}
              onChange={(e) =>
                setFieldValues(
                  "contactNo",
                  e.target.value,
                  "",
                  formLabels,
                  setFormError,
                  form,
                  setForm
                )
              }
            />
            {formError.contactNo && <Error error={formError.contactNo} />}
          </FormControl>
        </div>
        <div className="settings-container-input-section">
          <span className="settings-container-input-label">
            Restaurant`s Address
          </span>
          <FormControl
            size="small"
            variant="outlined"
            className="settings-container-input"
          >
            <TextField
              id="outlined-adornment-password"
              type="text"
              variant="outlined"
              size="small"
              placeholder="468-C, SMCHS Block2, Karachi"
              value={form.location}
              InputProps={{
                endAdornment: (
                  <img
                    src={LocationIcon}
                    className="settings-container-location-icon"
                  />
                ),
              }}
              onChange={(e) =>
                setFieldValues(
                  "location",
                  e.target.value,
                  "",
                  formLabels,
                  setFormError,
                  form,
                  setForm
                )
              }
            />
            {formError.location && <Error error={formError.location} />}
          </FormControl>
        </div>
        <div className="settings-container-input-section">
          <span className="settings-container-input-label">
            Restaurant Categories
          </span>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            displayEmpty
            multiple
            className="input"
            value={form.catId}
            onChange={handleChange}
            input={<BootstrapInput />}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <span>Select Category</span>;
              }
              return selected.map((cat) => cat.name).join(", ");
            }}
            MenuProps={MenuProps}
          >
            <MenuItem disabled value="">
              <span>Select Category</span>
            </MenuItem>
            {allCategories &&
              allCategories?.map((category, index) => (
                <MenuItem key={index} value={category}>
                  <Checkbox
                    checked={
                      form.catId.findIndex((cat) => cat.id === category.id) > -1
                    }
                    style={{
                      color: "#15C070",
                    }}
                  />
                  <ListItemText primary={category.name} />
                </MenuItem>
              ))}
          </Select>
          {formError.catId && <Error error={formError.catId} />}
        </div>
        <div className="settings-container-input-section">
          <span className="settings-container-input-label">
            Restaurants Timings
          </span>
          <div className="settings-container-restaurant-timings-inputs">
            <div className="column-field">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justifyContent="space-around">
                  <KeyboardTimePicker
                    margin="normal"
                    id="open-time"
                    className="time"
                    label="Opening Time"
                    value={form.openTime}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(val) =>
                      setFieldValues(
                        "openTime",
                        val,
                        "",
                        formLabels,
                        setFormError,
                        form,
                        setForm
                      )
                    }
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              {formError.openTime && <Error error={formError.openTime} />}
            </div>
            <div className="column-field">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justifyContent="space-around">
                  <KeyboardTimePicker
                    margin="normal"
                    id="close-time"
                    className="time"
                    label="Closing Time"
                    value={form.closeTime}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(val) =>
                      setFieldValues(
                        "closeTime",
                        val,
                        "",
                        formLabels,
                        setFormError,
                        form,
                        setForm
                      )
                    }
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              {formError.closeTime && <Error error={formError.closeTime} />}
            </div>
          </div>
        </div>
        <div className="settings-container-input-section">
          <span className="settings-container-input-label">
            Choose Color Theme
          </span>
          <ColorPicker
            customStyle={{ height: "55px" }}
            value={restaurant?.themeColor}
            onSelect={(val) =>
              setFieldValues(
                "themeColor",
                val,
                "",
                formLabels,
                setFormError,
                form,
                setForm
              )
            }
          />
          {formError.themeColor && <Error error={formError.themeColor} />}
        </div>
        <div className="settings-container-input-section">
          <CustomButton title="Save" navigateTo={editRestaurantInformation} />
        </div>
      </div>
      <div className="settings-container-right-section restaurant-info-right-section">
        <div className="settings-container-card">
          <h3 className="restaurant-titles">Restaurant Profile Picture</h3>
          <ImageUploading
            onChange={(imageList) => handleUpload(imageList[0].file, "logo")}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({ onImageUpload }) => (
              <div
                className="settings-container-restaurant-profile"
                onClick={onImageUpload}
              >
                {form?.logo || restaurant?.logo ? (
                  <img
                    src={form?.logo || restaurant?.logo}
                    className="selected-image"
                    loading="lazy"
                  />
                ) : (
                  <img
                    src={UploadingImage}
                    alt=""
                    width="100px"
                    height="100px"
                    className="logo-icon"
                  />
                )}
              </div>
            )}
          </ImageUploading>
          {formError.logo && <Error error={formError.logo} />}
        </div>
        <div className="settings-container-card">
          <h3 className="restaurant-titles">Restaurant Cover Picture</h3>
          <ImageUploading
            onChange={(imageList) =>
              handleUpload(imageList[0].file, "coverImg")
            }
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({ onImageUpload }) => (
              <div
                className="settings-container-restaurant-cover"
                onClick={onImageUpload}
              >
                {form?.coverImg || restaurant?.coverImg ? (
                  <img
                    src={form?.coverImg || restaurant?.coverImg}
                    className="selected-image"
                  />
                ) : (
                  <img src={VectorImage} alt="" className="logo-icon" />
                )}
              </div>
            )}
          </ImageUploading>
          {formError.coverImg && <Error error={formError.coverImg} />}
        </div>
        <div className="settings-container-card">
          <h3 className="restaurant-titles">Restaurant QR Code</h3>
          <div className="settings-container-qr-code">
            <QRCode
              size="200"
              value={`www.cerwiz.com/restaurant/${restaurant?.id}`}
              logoImage={restaurant.logo}
              eyeRadius={[
                [10, 10, 0, 10], // top/left eye
                [10, 10, 10, 0], // top/right eye
                [10, 0, 10, 10], // bottom/left
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
RestaurantInformation.propTypes = {
  editRestaurantInfo: PropTypes.func.isRequired,
  getRestaurantById: PropTypes.func.isRequired,
  callInProcess: PropTypes.func.isRequired,
  restaurant: PropTypes.any.isRequired,
  getCategories: PropTypes.func.isRequired,
  categories: PropTypes.any,
};
