import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Orders from "./Orders";
import { getOrders } from "./../store/order.actions";
import { callInProcess } from "store/core/core.actions";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOrders,
      callInProcess,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  const { order } = state;
  return {
    orders: order.orders,
    recentOrders: order.recentOrders,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
