/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import appEpic from "./store/app.epics";
import { compose, createStore, applyMiddleware } from "redux";
import { createEpicMiddleware } from "redux-most";
import appReducer from "./store/app.reducer";
import "assets/css/material-dashboard-react.css?v=1.10.0";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const epicMiddleware = createEpicMiddleware(appEpic);
export const store = createStore(
  appReducer,
  composeEnhancers(applyMiddleware(epicMiddleware))
);

ReactDOM.render(<App />, document.getElementById("root"));
