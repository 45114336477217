import React from "react";
import "./Settings.css";
// import TextField from "@material-ui/core/TextField";
// import CustomButton from "../../modules/auth/CustomButton/index";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import RestaurantInformation from "views/RestaurantInformation";
import AccountSettings from "views/AccountSettings";
// import MenuCard from "views/MenuCard/MenuCard";

function TabPanel(props) {
  // eslint-disable-next-line react/prop-types
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Settings() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const navigateTo = () => setShowCreateMenuModal(true);

  return (
    <div className="menu-container">
      <div className="menu-tab-container">
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
          className="tabs menu-tabs"
        >
          <Tab
            label="Restaurant Inforamtion"
            className="settings-tabs"
            {...a11yProps(0)}
          />
          <Tab
            label="Account Settings"
            className="settings-tabs"
            {...a11yProps(1)}
          />
        </Tabs>
        <div className="menu-section2">
          <TabPanel value={value} index={0}>
            <RestaurantInformation />
          </TabPanel>
          <TabPanel TabPanel value={value} index={1}>
            <AccountSettings />
          </TabPanel>
        </div>
      </div>
    </div>
  );
}
Settings.propTypes = {};
