import forge from "mappersmith";
import { apiBaseUrl } from "../../../constants/api-baseUrl";
import { ServerErrorMiddleware } from "../../middleware/server-error-middleware";
import { AccessTokenMiddleware } from "../../middleware/access-token-middleware";
import EncodeJson from "mappersmith/middleware/encode-json";
import { ServerResponseMiddleware } from "../../middleware/server-response-middleware";

const ServerErrors = ServerErrorMiddleware();
const ServerResponse = ServerResponseMiddleware();
const AccesTokenMiddleware = AccessTokenMiddleware();
const client = forge({
  host: apiBaseUrl,
  middleware: [ServerErrors, ServerResponse, AccesTokenMiddleware, EncodeJson],
  resources: {
    Menu: {
      getMenus: {
        path: "/item",
        method: "get",
      },
      getCategories: {
        path: "/category",
        method: "get",
      },
      getMenuDetails: {
        path: "/item/{id}",
        method: "get",
        bodyAttr: "menuId",
      },
      getTabs: {
        path: "/tabs",
        method: "get",
      },
      createTab: {
        path: "/tabs",
        method: "post",
        bodyAttr: "params",
      },
      createMenu: {
        path: "/item",
        method: "post",
        bodyAttr: "params",
      },
      editMenu: {
        path: "/item/{id}",
        method: "put",
        bodyAttr: "params",
      },
      deleteTab: {
        path: "/tabs/{id}",
        method: "delete",
      },
      deleteMenu: {
        path: "/item/{id}",
        method: "delete",
      },
    },
  },
});

export const menus = ({ type, params }) => {
  console.log(type);
  return client.Menu.getMenus(params);
};

export const getCategories = ({ params }) => {
  return client.Menu.getCategories(params);
};

export const menuDetails = ({ id }) => {
  console.log(id);
  return client.Menu.getMenuDetails({ id });
};

export const tabs = ({ type, params }) => {
  console.log(type);
  return client.Menu.getTabs(params);
};

export const createTab = ({ params }) => {
  console.log(params);
  return client.Menu.createTab({ params });
};

export const createMenu = ({ params }) => {
  console.log(params);
  return client.Menu.createMenu({ params });
};

export const editMenu = ({ params, id }) => {
  return client.Menu.editMenu({ params, id });
};

export const deleteTab = ({ id }) => {
  return client.Menu.deleteTab({ id });
};

export const deleteMenu = ({ id }) => {
  return client.Menu.deleteMenu({ id });
};
