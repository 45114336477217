import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PdfTable from "./PdfTable";
import { getTableById } from "../../modules/table/store/table.actions";
import { callInProcess } from "store/core/core.actions";
import { getRestaurantById } from "../../modules/auth/store/auth.actions";

const mapStateToProps = (state) => {
  const { table, auth } = state;
  return {
    table: table.table,
    restaurant: auth.restaurant,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTableById,
      getRestaurantById,
      callInProcess,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PdfTable);
