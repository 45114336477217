import { combineEpics, select } from "redux-most";
import * as most from "most";
import {
  GET_TABLES,
  getTablesSuccess,
  CREATE_TABLE,
  getTables,
  GET_TABLE_BY_ID,
  getTableByIdSuccess,
  EDIT_TABLE,
  DELETE_TABLE,
} from "./table.actions";
import { callInProcess } from "store/core/core.actions";
import { createTable } from "core/services/table/table.service";
import { tables } from "core/services/table/table.service";
import { showToast } from "store/toast/toast.actions";
import { getTableById } from "core/services/table/table.service";
import { deleteTable } from "core/services/table/table.service";
import { editTable } from "core/services/table/table.service";

const tablesEpic = ($actions, $store) =>
  $actions.thru(select(GET_TABLES)).flatMap((action) => {
    const { auth } = $store.getState();
    return most
      .fromPromise(
        tables({ params: action.payload.params, id: auth.user.resId })
      )
      .flatMap((response) => {
        console.log(response);
        return most.from([
          getTablesSuccess({
            tables: response,
          }),
          callInProcess(false),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const createTableEpic = ($actions, $store) =>
  $actions.thru(select(CREATE_TABLE)).flatMap((action) => {
    const { auth } = $store.getState();
    const tableData = { ...action.payload.params, resId: auth.user.resId };
    return most
      .fromPromise(createTable(tableData))
      .flatMap((response) => {
        console.log(response);
        return most.from([
          getTables({ params: { page: 1, limit: 100 } }),
          callInProcess(false),
          showToast({
            message: "Create Table Successfully",
            type: "success",
          }),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const getTableByIdEpic = ($actions) =>
  $actions.thru(select(GET_TABLE_BY_ID)).flatMap((action) => {
    console.log("epic ====>>" + action.payload);
    return most
      .fromPromise(getTableById(action.payload.id))
      .flatMap((response) => {
        console.log(response);
        return most.from([
          getTableByIdSuccess({
            table: response,
          }),
          callInProcess(false),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const editTableEpic = ($actions, $store) =>
  $actions.thru(select(EDIT_TABLE)).flatMap((action) => {
    const { auth } = $store.getState();
    const tableData = { ...action.payload.params, resId: auth.user.resId };
    return most
      .fromPromise(editTable({ params: tableData, id: auth.user.resId }))
      .flatMap((response) => {
        console.log(response);
        return most.from([
          getTables({ params: { page: 1, limit: 100 } }),
          callInProcess(false),
          showToast({
            message: "Edit Table Successfully",
            type: "success",
          }),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const deleteTableEpic = ($actions) =>
  $actions.thru(select(DELETE_TABLE)).flatMap((action) => {
    return most
      .fromPromise(deleteTable(action.payload.id))
      .flatMap((response) => {
        console.log(response);
        return most.from([
          getTables({ params: { page: 1, limit: 100 } }),
          callInProcess(false),
          showToast({
            message: "Delete Table Successfully",
            type: "success",
          }),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

export default combineEpics([
  tablesEpic,
  createTableEpic,
  getTableByIdEpic,
  editTableEpic,
  deleteTableEpic,
]);
