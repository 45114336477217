import React, { useEffect, useRef, useState } from "react";
import "./PdfTable.css";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import QRCodeImage from "assets/img/table-qr-card.jpg";
// import QRCode from "qrcode.react";
import { savePDF } from "@progress/kendo-react-pdf";
import CustomButton from "modules/auth/CustomButton/CustomButton";
import { QRCode } from "react-qrcode-logo";

export default function PdfTable({
  getTableById,
  table,
  callInProcess,
  getRestaurantById,
  restaurant,
}) {
  const { id } = useParams();
  const [selectedTable, setSelectedTable] = new useState(null);
  const [userLogo, setUserLogo] = new useState(null);

  useEffect(() => {
    callInProcess(true);
    getTableById(Number(id));
    getRestaurantById();
  }, []);

  useEffect(() => {
    setSelectedTable(table);
  }, [table]);

  useEffect(() => {
    setUserLogo(restaurant.logo);
  }, [restaurant]);

  const contentArea = useRef(null);

  const downloadPDF = () => {
    savePDF(contentArea.current, { paperSize: "A4" });
  };

  return (
    <div className="pdf-container">
      <div className="noExport">
        <h3 className="create-table-qr-title">QR Code Card</h3>
        {/* <span className="download-pdf" onClick={downloadPDF}>
          Print
        </span> */}
        <CustomButton
          title="Print"
          customStyles={{ width: "131px" }}
          navigateTo={downloadPDF}
        />
      </div>
      <div className="pdf-inner-container" ref={contentArea}>
        <div className="qr-code-image">
          <div className="qr-table-title-section">
            {/* <p className="qr-table-title">{selectedTable?.name}</p> */}
            <img className="qr-table-img" src={userLogo} />
          </div>
          <div className="qr-table-no-section">
            <p className="qr-table-no">Table No: {selectedTable?.id}</p>
          </div>
          <img src={QRCodeImage} />
          <div className="qr-code">
            <QRCode
              size="332"
              value={`www.cerwiz.com/table/` + selectedTable?.id}
              logoImage={userLogo}
              eyeRadius={[
                [10, 10, 0, 10], // top/left eye
                [10, 10, 10, 0], // top/right eye
                [10, 0, 10, 10], // bottom/left
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
PdfTable.propTypes = {
  getTableById: PropTypes.func,
  callInProcess: PropTypes.func,
  getRestaurantById: PropTypes.func,
  restaurant: PropTypes.any,
  table: PropTypes.object,
};
