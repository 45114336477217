import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Signup from "./Signup";
import { signUp } from "../store/auth.actions";
import { callInProcess } from "store/core/core.actions";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signUp,
      callInProcess,
    },
    dispatch
  );

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
