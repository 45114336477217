import React, { useState } from "react";
import "./AccountSettings.css";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Error from "components/Error/Error";
import CustomButton from "modules/auth/CustomButton/CustomButton";
import { PropTypes } from "prop-types";
import { requiredFiled } from "constants/errors";
import { passwordNotMatch } from "constants/errors";
import { setFieldValues } from "constants/validation";
import { checkFormValid } from "constants/validation";
import EyeCrossedIcon from "assets/icons/eye-crossed-icon.png";

export default function AccountSettings({ updatePassword }) {
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const formLabel = {
    oldPassword: "Old Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
  };

  let [form, setForm] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [formError, setFormError] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const changePassword = () => {
    if (!checkFormValid(form, formError, setFormError, formLabel)) {
      return;
    }
    if (formError.confirmPassword === "Password do not match") {
      return;
    }
    const data = {
      oldPass: form.oldPassword,
      newPass: form.newPassword,
    };
    updatePassword(data);
  };

  const checkFieldValues = (value, fieldName, propertyName) => {
    let errorMsg = "";
    const passwordForm = {
      invalid: {},
      error: {},
    };
    if (value.length === 0 && propertyName !== "confirmPassword") {
      errorMsg = requiredFiled(fieldName);
    } else if (
      (propertyName === "confirmPassword" &&
        form.newPassword &&
        value !== form.newPassword) ||
      (form.confirmPassword &&
        propertyName === "newPassword" &&
        value !== form.confirmPassword)
    ) {
      if (propertyName === "confirmPassword") {
        errorMsg = passwordNotMatch;
      }
      passwordForm.invalid = {
        // password: false,
        confirmPassword: true,
      };
      passwordForm.error = {
        // password: '',
        confirmPassword: passwordNotMatch,
      };
    }
    if (
      !errorMsg &&
      (propertyName === "confirmPassword" || propertyName === "newPassword")
    ) {
      const password =
        propertyName === "newPassword" ? value : form.newPassword;
      const confirmPassword =
        propertyName === "confirmPassword" ? value : form.confirmPassword;
      if (password === confirmPassword) {
        passwordForm.invalid = {
          confirmPassword: false,
        };
        passwordForm.error = {
          confirmPassword: "",
        };
      }
    }
    setForm((prev) => ({
      ...prev,
      [propertyName]: value,
    }));
    setFormError((prev) => ({
      ...prev,
      ...passwordForm.error,
      [propertyName]: errorMsg,
    }));
  };

  return (
    <div className="settings-container-input-section">
      <div className="settings-container-input-section passwords-input-section">
        <span className="settings-container-input-label">Old Password</span>
        <FormControl
          size="small"
          variant="outlined"
          className="settings-container-input"
        >
          <TextField
            id="outlined-adornment-password"
            type={showPassword.oldPassword ? "type" : "password"}
            variant="outlined"
            size="small"
            placeholder="Enter old password"
            value={form.oldPassword}
            onChange={(e) =>
              setFieldValues(
                "oldPassword",
                e.target.value,
                "",
                { oldPassword: "Old Password" },
                setFormError,
                form,
                setForm
              )
            }
            InputProps={{
              endAdornment: (
                <img
                  src={EyeCrossedIcon}
                  className="eye-crossed-icon"
                  onClick={() =>
                    setShowPassword({
                      ...showPassword,
                      oldPassword: !showPassword.oldPassword,
                    })
                  }
                />
              ),
            }}
          />
          {formError.oldPassword && <Error error={formError.oldPassword} />}
        </FormControl>
      </div>
      <div className="settings-container-input-section passwords-input-section">
        <span className="settings-container-input-label">New Password</span>
        <FormControl
          size="small"
          variant="outlined"
          className="settings-container-input"
        >
          <TextField
            id="outlined-adornment-password"
            type={showPassword.newPassword ? "type" : "password"}
            variant="outlined"
            size="small"
            placeholder="Enter new password"
            value={form.newPassword}
            onChange={(e) =>
              checkFieldValues(e.target.value, "New Password", "newPassword")
            }
            InputProps={{
              endAdornment: (
                <img
                  src={EyeCrossedIcon}
                  className="eye-crossed-icon"
                  onClick={() =>
                    setShowPassword({
                      ...showPassword,
                      newPassword: !showPassword.newPassword,
                    })
                  }
                />
              ),
            }}
          />
          {formError.newPassword && <Error error={formError.newPassword} />}
        </FormControl>
      </div>
      <div className="settings-container-input-section passwords-input-section">
        <span className="settings-container-input-label">Confirm Password</span>
        <FormControl
          size="small"
          variant="outlined"
          className="settings-container-input"
        >
          <TextField
            id="outlined-adornment-password"
            type={showPassword.confirmPassword ? "type" : "password"}
            variant="outlined"
            size="small"
            placeholder="RE-Type your password"
            value={form.confirmPassword}
            onChange={(e) =>
              checkFieldValues(
                e.target.value,
                "Confirm Password",
                "confirmPassword"
              )
            }
            InputProps={{
              endAdornment: (
                <img
                  src={EyeCrossedIcon}
                  className="eye-crossed-icon"
                  onClick={() =>
                    setShowPassword({
                      ...showPassword,
                      confirmPassword: !showPassword.confirmPassword,
                    })
                  }
                />
              ),
            }}
          />
          {formError.confirmPassword && (
            <Error error={formError.confirmPassword} />
          )}
        </FormControl>
        <CustomButton
          title="Submit"
          customStyles={{ marginTop: "40px" }}
          navigateTo={() => changePassword()}
        />
      </div>
    </div>
  );
}
AccountSettings.propTypes = {
  updatePassword: PropTypes.func.isRequired,
};
