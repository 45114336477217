import React from "react";
import "./ConfirmationModal.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
// import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../../modules/auth/CustomButton/CustomButton";
import { PropTypes } from "prop-types";

const styles = (theme) => ({
  root: {
    margin: 0,
    borderBottom: "none",
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: "25px",
    top: "25px",
    padding: "0px",
    width: "16px",
    height: "16px",
    color: theme.palette.grey[500],
    "&:hover": {
      background: "transparent",
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { classes, onClose } = props;
  return (
    // <MuiDialogTitle disableTypography className={classes.root} {...other}>
    //   {onClose ? (
    //   ) : null}
    // </MuiDialogTitle>
    <IconButton
      aria-label="close"
      className={classes.closeButton}
      onClick={onClose}
    >
      <CloseIcon />
    </IconButton>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function ConfirmationModal({
  showConfirmationModal,
  setShowConfirmationModal,
  message,
  submit,
  // getRestaurantById,
}) {
  const useStyles = makeStyles(() => ({
    backDrop: {
      backdropFilter: "blur(3px)",
      backgroundColor: "rgba(50,50,30,0.1)",
    },
  }));

  const classes = useStyles();
  return (
    <Dialog
      onClose={() => setShowConfirmationModal(null)}
      aria-labelledby="customized-dialog-title"
      open={showConfirmationModal}
      fullWidth="sm"
      maxWidth="sm"
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => setShowConfirmationModal(null)}
      ></DialogTitle>
      <DialogContent>
        <div className="confirm-dailog-container">
          <div className="confirm-dailog-message">
            <h3>
              {message + " " + '"' + showConfirmationModal.name + '"' + " ?"}{" "}
            </h3>
          </div>
          <div className="create-menu-right-second-section">
            <CustomButton
              title="Cancel"
              customStyles={{
                width: "98px",
                backgroundColor: "#ffffff",
                color: "#42526E",
                border: "1px solid rgba(66, 82, 110, 0.7)",
                margin: "0px",
                marginRight: "20px",
              }}
              navigateTo={() => setShowConfirmationModal(null)}
            />
            <CustomButton
              title="Confirm"
              customStyles={{
                backgroundColor: "rgb(220, 0, 78)",
                width: "131px",
                margin: "0px",
              }}
              navigateTo={() => submit()}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
ConfirmationModal.propTypes = {
  showConfirmationModal: PropTypes.object,
  setShowConfirmationModal: PropTypes.Func,
  message: PropTypes.string,
  submit: PropTypes.func.isRequired,
};
