import React from "react";
import "./RegistrationSteps.css";
import Maps from "views/Maps/Maps.js";
import { PropTypes } from "prop-types";

const RegistrationStep3 = ({
  getLocation,
  selectedLat,
  selectedLng,
  restaurantName,
}) => {
  return (
    <Maps
      getLocation={getLocation}
      selectedLat={selectedLat}
      selectedLng={selectedLng}
      restaurantName={restaurantName}
    />
  );
};

RegistrationStep3.propTypes = {
  getLocation: PropTypes.func,
  selectedLat: PropTypes.string,
  selectedLng: PropTypes.string,
  restaurantName: PropTypes.string,
};
export default RegistrationStep3;
// TODO: hide it now, will use later
// <div className="content-container">
// {/* <h2>Buy Subscription</h2>
// <div className="card-container-section">
//   <Card className="card-container">
//     <CardContent className="card-container-content">
//       <div className="section-1">
//         <Typography variant="h5" component="h3">
//           Free
//         </Typography>
//       </div>
//       <div className="section-2">
//         <Typography className={classes.pos} color="textSecondary">
//           <img src={SuccessImage} className="icons" />
//           Analytics
//         </Typography>
//         <Typography className={classes.pos} color="textSecondary">
//           <img src={SuccessImage} className="icons" />
//           Payment Management
//         </Typography>
//         <Typography className={classes.pos} color="textSecondary">
//           <img src={CancelImage} className="icons" />
//           Waiter Management
//         </Typography>
//       </div>
//       <div className="section-3">
//         <Typography variant="body2" component="p">
//           Selected
//         </Typography>
//       </div>
//     </CardContent>
//   </Card>
//   <Card className="card-container">
//     <CardContent className="card-container-content">
//       <div className="section-1">
//         <Typography variant="h5" component="h3">
//           $19
//         </Typography>
//       </div>
//       <div className="section-2">
//         <Typography className={classes.pos} color="textSecondary">
//           <img src={SuccessImage} className="icons" />
//           Analytics
//         </Typography>
//         <Typography className={classes.pos} color="textSecondary">
//           <img src={SuccessImage} className="icons" />
//           Payment Management
//         </Typography>
//         <Typography className={classes.pos} color="textSecondary">
//           <img src={CancelImage} className="icons" />
//           Waiter Management
//         </Typography>
//       </div>
//       <div className="section-3">
//         <Typography variant="body2" component="p">
//           Buy Now
//         </Typography>
//       </div>
//     </CardContent>
//   </Card>
// </div> */}
// </div>
