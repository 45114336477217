export const GET_DEALS = "GET_DEALS";
export const GET_DEALS_SUCCESS = "GET_DEALS_SUCCESS";
export const GET_DEALS_FAILURE = "GET_DEALS_FAILURE";
export const CREATE_DEAL = "CREATE_DEAL";
export const CREATE_DEAL_SUCCESS = "CREATE_DEAL_SUCCESS";
export const CREATE_DEAL_FAILURE = "CREATE_DEAL_FAILURE";
// export const GET_TABLE_BY_ID = "GET_TABLE_BY_ID";
// export const GET_TABLE_BY_ID_SUCCESS = "GET_TABLE_BY_ID_SUCCESS";

export const getDeals = () => ({
  type: GET_DEALS,
});

export const getDealsSuccess = ({ deals }) => ({
  type: GET_DEALS_SUCCESS,
  payload: { deals },
});

export const createDeal = (params) => ({
  type: CREATE_DEAL,
  payload: params,
});

export const createDealSuccess = ({ deals }) => ({
  type: CREATE_DEAL_SUCCESS,
  payload: { deals },
});
