import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Reset from "./Reset";
import { callInProcess } from "store/core/core.actions";
import { reset } from "../store/auth.actions";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      callInProcess,
      reset,
    },
    dispatch
  );

const mapStateToProps = () => {};

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
