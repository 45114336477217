import { GET_DEALS_SUCCESS } from "./deals.actions";

const INITIAL_STATE = {
  deals: {
    data: [],
  },
};

const dealsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DEALS_SUCCESS:
      return {
        ...state,
        deals: action.payload.deals,
      };
    // case CREATE_DEAL_SUCCESS:
    //   return {
    //     ...state,
    //     tables: {
    //       ...state.tables,
    //       data: [action.payload.table, ...state.tables.data],
    //     },
    //   };
    default:
      return state;
  }
};

export default dealsReducer;
