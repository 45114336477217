import React from "react";
import { PropTypes } from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { PaymentMethodMapping } from "constants/payment";
import { OrderStatusMapping } from "constants/payment";
import { OrderStatusColorMapping } from "constants/payment";

export default function PaymentViaCredit({
  columns,
  page,
  rowsPerPage,
  allPayments,
  handleChangePage,
  handleChangeRowsPerPage,
  payments,
}) {
  return (
    <>
      {allPayments?.length ? (
        <Card plain>
          <CardBody>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allPayments.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell>{row.table?.id || "N/A"}</TableCell>
                        <TableCell>{row.table?.name || "N/A"}</TableCell>
                        <TableCell>{row.customer?.Name || "Mohsin"}</TableCell>
                        <TableCell align="right">
                          {row.items.length + " items"}
                        </TableCell>
                        <TableCell align="right">{row.totalPrice}</TableCell>
                        <TableCell align="right">
                          {new Date(row.createdAt).toLocaleTimeString()}
                        </TableCell>
                        <TableCell align="right">
                          {PaymentMethodMapping[row.paymentMethod]}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            color: OrderStatusColorMapping[row.status],
                          }}
                        >
                          {OrderStatusMapping[row.status]}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={payments.totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </CardBody>
        </Card>
      ) : (
        <div className="no-items-found">No Payment Found</div>
      )}
    </>
  );
}
PaymentViaCredit.propTypes = {
  columns: PropTypes.array,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  allPayments: PropTypes.array,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  payments: PropTypes.any,
};
