import React, { useEffect } from "react";
import "./Tables.css";
import TextField from "@material-ui/core/TextField";
// import CustomButton from "../../../components/CustomButtons/Button";
import TableCard from "views/TableCard";
import { PropTypes } from "prop-types";
import CreateTableModal from "views/CreateTableModal";
import SearchIcon from "assets/icons/search-icon.png";
import CustomButton from "modules/auth/CustomButton/CustomButton";
import ShowQRCodeModal from "views/ShowQRCodeModal";

export default function Tables({ getTables, tables, callInProcess }) {
  const [showCreateTableModal, setShowCreateTableModal] = React.useState(0);
  const [selectedTable, setSelectedTable] = React.useState(null);
  const [allTables, setTables] = React.useState([]);
  const navigateTo = () => setShowCreateTableModal(true);
  const showQrModal = (table) => table;
  useEffect(() => {
    callInProcess(true);
    getTables({ params: { page: 1, limit: 100 } });
  }, [getTables]);
  useEffect(() => {
    console.log(tables);
    setTables(tables.data);
  }, [tables]);

  const onSearch = (event) => {
    console.log(allTables);
    const searchValue = event.target.value.toLowerCase();
    const tempTables = [...tables.data];
    setTables(
      tempTables.filter(
        (table) =>
          table.name.toLowerCase().indexOf(searchValue) > -1 ||
          table.name.toLowerCase() === searchValue
      )
    );
  };

  return (
    <div className="tables-container">
      <div className="section1 menu-section1">
        <div>
          <h3 className="main-title">Manage Tables</h3>
        </div>
        <div className="menu-section1-right-section">
          <CustomButton
            title="Create New Table"
            navigateTo={navigateTo}
            customStyles={{
              width: "175px",
              height: "36px",
              borderRadius: "8px",
              padding: "0",
              margin: "0px 30px",
            }}
          />
          <TextField
            id="outlined-secondary"
            type="text"
            variant="outlined"
            size="small"
            placeholder="Search"
            className="menu-section1-right-section-input"
            InputProps={{
              startAdornment: <img src={SearchIcon} className="search-icon" />,
            }}
            onChange={onSearch}
          />
        </div>
      </div>
      {allTables.length ? (
        <div className="menu-tab-container">
          <TableCard
            tables={allTables}
            showCreateTableModal={showCreateTableModal}
            setShowCreateTableModal={setShowCreateTableModal}
            showQrModal={showQrModal}
          />
        </div>
      ) : (
        <div className="no-items-found">No Table Found</div>
      )}
      {showCreateTableModal ? (
        <CreateTableModal
          showCreateTableModal={showCreateTableModal}
          setShowCreateTableModal={setShowCreateTableModal}
        />
      ) : (
        <></>
      )}
      {selectedTable ? (
        <ShowQRCodeModal
          selectedTable={selectedTable}
          setSelectedTable={setSelectedTable}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
Tables.propTypes = {
  showCreateTableModal: PropTypes.boolean,
  setShowCreateTableModal: PropTypes.Func,
  getTables: PropTypes.func.isRequired,
  callInProcess: PropTypes.func.isRequired,
  tables: PropTypes.any.isRequired,
};
