import React, { useState } from "react";
import Sliderlayout from "../Sliderlayout";
// import Socialbuttons from "../Socialbuttons";
import CustomButton from "../CustomButton";
import FormControl from "@material-ui/core/FormControl";
import { NavLink } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import UserIcon from "assets/icons/user.png";
import LockIcon from "assets/icons/lock.png";
// import { useHistory } from "react-router-dom";
import "../Auth.css";
import { PropTypes } from "prop-types";
import Error from "../../../components/Error/Error";
import {
  checkFormValid,
  checkForRequiredField,
} from "../../../constants/validation";
import { passwordNotMatch } from "constants/errors";
import { requiredFiled } from "constants/errors";
import EyeCrossedIcon from "assets/icons/eye-crossed-icon.png";
const Signup = ({ signUp, callInProcess }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formLabels = {
    email: "Email",
    password: "Password",
    confirmPassword: "Confirm Password",
  };
  const [form, setForm] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [formErrorState, setFormErrorState] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const setFieldValues = (field, value) => {
    const errMessage = checkForRequiredField(field, value, formLabels);
    setFormErrorState((prev) => ({
      ...prev,
      [field]: errMessage,
    }));
    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const checkFieldValues = (value, fieldName, propertyName) => {
    let errorMsg = "";
    const passwordForm = {
      invalid: {},
      error: {},
    };
    if (value.length === 0 && propertyName !== "confirmPassword") {
      errorMsg = requiredFiled(fieldName);
    } else if (
      (propertyName === "confirmPassword" &&
        form.password &&
        value !== form.password) ||
      (form.confirmPassword &&
        propertyName === "password" &&
        value !== form.confirmPassword)
    ) {
      if (propertyName === "confirmPassword") {
        errorMsg = passwordNotMatch;
      }
      passwordForm.invalid = {
        // password: false,
        confirmPassword: true,
      };
      passwordForm.error = {
        // password: '',
        confirmPassword: passwordNotMatch,
      };
    }
    if (
      !errorMsg &&
      (propertyName === "confirmPassword" || propertyName === "password")
    ) {
      const password = propertyName === "password" ? value : form.password;
      const confirmPassword =
        propertyName === "confirmPassword" ? value : form.confirmPassword;
      if (password === confirmPassword) {
        passwordForm.invalid = {
          confirmPassword: false,
        };
        passwordForm.error = {
          confirmPassword: "",
        };
      }
    }
    setForm((prev) => ({
      ...prev,
      [propertyName]: value,
    }));
    setFormErrorState((prev) => ({
      ...prev,
      ...passwordForm.error,
      [propertyName]: errorMsg,
    }));
  };

  const validateAndSignUp = () => {
    if (!checkFormValid(form, formErrorState, setFormErrorState, formLabels)) {
      return;
    }
    if (formErrorState.confirmPassword === "Password do not match") {
      return;
    }
    callInProcess(true);
    signUp({
      email: form.email,
      Name: "test1",
      password: form.password,
      address: "karachi",
      phone: "0333333333",
      profileImg: "",
      resId: 0,
      roleId: 1,
    });
    console.log(checkFormValid);
  };
  return (
    <div className="container">
      <div className="slider">
        <Sliderlayout />
      </div>
      <div className="login">
        <div className="login-container">
          <div className="login-inner-container">
            <h2>Hey,</h2>
            <h2>Signup Now</h2>
            <h4 className="login-inner-title">
              Already have an account?
              <NavLink className="links" to={"/login"}>
                Login Now
              </NavLink>
            </h4>
            <div>
              <FormControl size="small" variant="outlined" className="inputs">
                <TextField
                  id="outlined-adornment-password"
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Username or Email"
                  InputProps={{
                    startAdornment: (
                      <img src={UserIcon} className="login-icons" />
                    ),
                  }}
                  onChange={(value) =>
                    setFieldValues("email", value.target.value)
                  }
                />
                {formErrorState.email && <Error error={formErrorState.email} />}
              </FormControl>
              <FormControl size="small" variant="outlined" className="inputs">
                <TextField
                  id="outlined-adornment-password"
                  type={showPassword ? "type" : "password"}
                  variant="outlined"
                  size="small"
                  placeholder="Password"
                  InputProps={{
                    startAdornment: (
                      <img src={LockIcon} className="login-icons" />
                    ),
                    endAdornment: (
                      <img
                        src={EyeCrossedIcon}
                        className="eye-crossed-icon"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ),
                  }}
                  onChange={(e) =>
                    checkFieldValues(e.target.value, "Password", "password")
                  }
                />
                {formErrorState.password && (
                  <Error error={formErrorState.password} />
                )}
              </FormControl>
              <FormControl size="small" variant="outlined" className="inputs">
                <TextField
                  id="outlined-adornment-password"
                  type={showConfirmPassword ? "type" : "password"}
                  variant="outlined"
                  size="small"
                  placeholder="Re-Type Password"
                  InputProps={{
                    startAdornment: (
                      <img src={LockIcon} className="login-icons" />
                    ),
                    endAdornment: (
                      <img
                        src={EyeCrossedIcon}
                        className="eye-crossed-icon"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      />
                    ),
                  }}
                  onChange={(e) =>
                    checkFieldValues(
                      e.target.value,
                      "Confirm Password",
                      "confirmPassword"
                    )
                  }
                />
                {formErrorState.confirmPassword && (
                  <Error error={formErrorState.confirmPassword} />
                )}
              </FormControl>
            </div>
            <CustomButton
              title="Signup"
              customStyles={{
                width: "153px",
                height: "60px",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: "20px",
                lineHeight: "23px",
              }}
              navigateTo={() => validateAndSignUp()}
            />
          </div>
          {/* <Socialbuttons /> */}
        </div>
      </div>
    </div>
  );
};

Signup.propTypes = {
  signUp: PropTypes.func.isRequired,
  callInProcess: PropTypes.func.isRequired,
};

export default Signup;
