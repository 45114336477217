import { combineEpics, select } from "redux-most";
import * as most from "most";
import {
  LOGIN,
  loginSuccess,
  SIGNUP,
  signUpSuccess,
  LOGOUT,
  GET_USER,
  getUserSuccess,
  getUser,
  IDENTIFY_AUTH_USER,
  logOut,
  RESEND_VERIFICATION_CODE,
  VERIFY_CODE,
  REGISTRATION,
  registrationSuccess,
  getRestaurantByIdSuccess,
  GET_RESTAURANT_BY_ID,
  EDIT_RESTAURANT_INFO,
  // editRestaurantInfoSuccess,
  RESET,
  UPDATE_PASSWORD,
  getRestaurantById,
} from "./auth.actions";
import {
  callInProcess,
  resetReducers,
  callInProcess1,
  callInProcess2,
} from "../../../store/core/core.actions";
import { showToast } from "../../../store/toast/toast.actions";
import { appInitialized } from "../../../store/core/core.actions";
import {
  login,
  signup,
  resendCode,
  verfifyCode,
  restaurantRegistration,
  editRestaurantInfo,
  reset,
  updatePassword,
  getRestaurantByIdService,
} from "../../../core/services/auth/auth.service";
import { getUserInfo } from "../../../core/services/user/user.service";
import AuthenticationTokenHelper from "../../../core/helper/AuthenticationTokenHelper";
// import { Redirect } from "react-router-dom";
const storeAuthInAsyncStorage = (token, user, restaurant = "") => {
  try {
    console.log(token, user);
    if (token) {
      localStorage.setItem("cerwiz-token", token);
    }
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    }
    if (restaurant) {
      localStorage.setItem("restaurant", restaurant);
    }
  } catch (error) {
    console.log(error);
  }
};

export const cleanAuth = () => {
  localStorage.removeItem("cerwiz-token");
  localStorage.removeItem("user");
  AuthenticationTokenHelper.authToken = null;
};

const loginEpic = ($actions) =>
  $actions.thru(select(LOGIN)).flatMap((action) => {
    return most
      .fromPromise(login(action.payload))
      .flatMap((response) => {
        console.log(
          "LoginResponse============================================"
        );
        const token = response.accessToken;
        AuthenticationTokenHelper.authToken = token;
        storeAuthInAsyncStorage(token);
        window.appHistory.push("/dashboard");
        return most.from([
          loginSuccess(token),
          getUser(token),
          callInProcess(false),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const getUserEpic = ($actions) =>
  $actions.thru(select(GET_USER)).flatMap((action) => {
    return most
      .fromPromise(getUserInfo())
      .flatMap((responseData) => {
        const actions = [];
        if (responseData) {
          // console.log("user token ", action.payload);
          const user = responseData;
          actions.push(getUserSuccess({ user, token: action.payload }));
          storeAuthInAsyncStorage("", user);
        } else {
          actions.push(logOut());
        }
        actions.push(
          appInitialized({
            initialized: false,
          })
        );
        actions.push(getRestaurantById());
        return most.from(actions);
      })
      .recoverWith((res) => {
        const actions = [
          appInitialized({
            initialized: false,
          }),
          callInProcess(false),
        ];
        const error = res.data();
        if (error.statusCode === 401) {
          cleanAuth();
          actions.push(resetReducers());
        }
        return most.from(actions);
      });
  });

const logOutEpic = ($actions) =>
  $actions.thru(select(LOGOUT)).flatMap(() => {
    window.appHistory?.push("/login");
    cleanAuth();
    return most.from([resetReducers(), callInProcess(false)]);
  });

const signUpEpic = ($actions) =>
  $actions.thru(select(SIGNUP)).flatMap((action) => {
    return most
      .fromPromise(signup(action.payload))
      .flatMap((res) => {
        const token = res.accessToken;
        AuthenticationTokenHelper.authToken = token;
        window.appHistory.push("/registration");
        return most.from([signUpSuccess({ token }), callInProcess(false)]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const registrationEpic = ($actions, $store) =>
  $actions.thru(select(REGISTRATION)).flatMap((action) => {
    return most
      .fromPromise(restaurantRegistration(action.payload))
      .flatMap((res) => {
        console.log(res);
        const { auth } = $store.getState();
        const token = auth.token;
        storeAuthInAsyncStorage(token, "", res);
        AuthenticationTokenHelper.authToken = token;
        setTimeout(() => {
          showToast({
            message: "Registration Successfully",
            type: "success",
            duration: 7000,
          }),
            window.appHistory.push("/dashboard");
        });
        return most.from([
          getUser(token),
          registrationSuccess({ restaurant: res }),
          callInProcess(false),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const verifyCodeEpic = ($actions) =>
  $actions.thru(select(VERIFY_CODE)).flatMap((action) => {
    return most
      .fromPromise(verfifyCode(action.payload))
      .flatMap((response) => {
        const { token } = response;
        AuthenticationTokenHelper.authToken = token;
        // resetStack({
        //   index: 0,
        //   routes: [{ name: "DrawerNavigator" }],
        // });
        localStorage.setItem("visited", "visited");
        storeAuthInAsyncStorage({ token });
        return most.from([
          signUpSuccess({ token }),
          callInProcess1(false),
          showToast({
            message: "Signup Successfully",
            type: "success",
            duration: 7000,
          }),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess1(false));
      });
  });

const resendVerificationCodeEpic = ($actions) =>
  $actions.thru(select(RESEND_VERIFICATION_CODE)).flatMap((action) => {
    return most
      .fromPromise(resendCode(action.payload))
      .flatMap(() => {
        return most.from([
          callInProcess2(false),
          showToast({
            message: "Code has been sent",
            type: "success",
          }),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess2(false));
      });
  });

const identifyAuthUserEpic = ($actions, $store) => {
  return $actions
    .thru(select(IDENTIFY_AUTH_USER))
    .flatMap((action) => {
      const { auth } = $store.getState();
      if (auth?.user?.id) {
        return most.of(
          appInitialized({
            initialized: false,
          })
        );
      }
      return most
        .fromPromise(Promise.resolve(localStorage.getItem("cerwiz-token")))
        .flatMap((token) => {
          const authToken =
            token ||
            action.payload.token ||
            AuthenticationTokenHelper.authToken ||
            null;
          if (authToken) {
            AuthenticationTokenHelper.authToken = token;
            return most.of(getUser(token));
          }
          return most.of(
            appInitialized({
              initialized: false,
            })
          );
        });
    })
    .recoverWith((error) => {
      const actions = [
        appInitialized({
          initialized: false,
        }),
        callInProcess(false),
      ];
      if (error.responseStatus === 401) {
        actions.push(logOut());
      }
      return most.from(actions);
    });
};

const getRestaurantByIdEpic = ($actions, $store) =>
  $actions.thru(select(GET_RESTAURANT_BY_ID)).flatMap(() => {
    const { auth } = $store.getState();
    const id = auth.user.resId;
    console.log("RestaurantID", id);
    return most
      .fromPromise(getRestaurantByIdService(id))
      .flatMap((response) => {
        return most.from([
          getRestaurantByIdSuccess({
            restaurant: response,
          }),
          callInProcess(false),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const editRestaurantInfoEpic = ($actions, $store) =>
  $actions.thru(select(EDIT_RESTAURANT_INFO)).flatMap((action) => {
    const { auth } = $store.getState();
    return most
      .fromPromise(
        editRestaurantInfo({ params: action.payload, id: auth.user.resId })
      )
      .flatMap(() => {
        return most.from([
          // editRestaurantInfoSuccess({
          //   restaurant: response,
          // }),
          getRestaurantById(),
          callInProcess(false),
          showToast({
            message: "Updated Successfully",
            type: "success",
          }),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const resetEpic = ($actions) =>
  $actions.thru(select(RESET)).flatMap((action) => {
    return most
      .fromPromise(reset(action.payload))
      .flatMap(() => {
        return most.from([
          callInProcess(false),
          showToast({
            message: "Reset Successfully",
            type: "success",
          }),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

const updatePasswordEpic = ($actions) =>
  $actions.thru(select(UPDATE_PASSWORD)).flatMap((action) => {
    return most
      .fromPromise(updatePassword(action.payload))
      .flatMap(() => {
        return most.from([
          callInProcess(false),
          showToast({
            message: "Password Updated Successfully",
            type: "success",
          }),
        ]);
      })
      .recoverWith(() => {
        return most.of(callInProcess(false));
      });
  });

export default combineEpics([
  loginEpic,
  signUpEpic,
  logOutEpic,
  getUserEpic,
  identifyAuthUserEpic,
  verifyCodeEpic,
  resendVerificationCodeEpic,
  registrationEpic,
  getRestaurantByIdEpic,
  editRestaurantInfoEpic,
  resetEpic,
  updatePasswordEpic,
]);
