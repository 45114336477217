import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import TextField from "@material-ui/core/TextField";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import ColorBox from "../ColorBox/ColorBox";
import "./Dashboard.css";
import SelectBox from "components/SelectBox/SelectBox";
import SearchIcon from "assets/icons/search-icon.png";
// import CalendarIcon from "assets/icons/calendar.png";
import FilterIcon from "assets/icons/filter.png";
import { orderStatus, orderColumns, dashboardBoxes } from "constants/constant";
import { PaymentMethodMapping } from "constants/payment";
import { OrderStatusMapping } from "constants/payment";
import { OrderStatusColorMapping } from "constants/payment";

export default function Dashboard({
  getOrders,
  getAnalytics,
  recentOrders,
  callInProcess,
  analytics,
  // getOrdersByStatus,
}) {
  // const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [params, setParams] = React.useState({
    page: page + 1,
    limit: rowsPerPage,
  });
  const [allOrders, setOrders] = React.useState([]);
  const [allAnalytics, setAllAnalytics] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setParams((val) => ({
      ...val,
      page: newPage + 1,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setParams((val) => ({
      ...val,
      limit: +event.target.value,
    }));
  };

  useEffect(() => {
    callInProcess(true);
    getOrders({
      type: "recentOrders",
      params,
    });
  }, [getOrders, page, rowsPerPage]);

  useEffect(() => {
    getAnalytics(true);
  }, []);

  useEffect(() => {
    setOrders(recentOrders.data);
  }, [recentOrders]);

  useEffect(() => {
    if (analytics) {
      const updatedBoxes = [];
      for (const box of dashboardBoxes) {
        updatedBoxes.push({
          ...box,
          number: analytics[box.property],
        });
      }
      setAllAnalytics(updatedBoxes);
    }
  }, [analytics]);

  const changeStatus = (value) => {
    const queryParams = {
      ...params,
      page: 1,
      status: value,
    };
    if (value === 0) {
      delete queryParams.status;
    }
    setPage(0);
    setParams(queryParams);
    callInProcess(true);
    getOrders({
      type: "recentOrders",
      params: queryParams,
    });
  };

  const onSearch = (event) => {
    const queryParams = {
      ...params,
      page: 1,
      search: event.target.value,
    };
    setPage(0);
    setParams(queryParams);
    getOrders({
      type: "recentOrders",
      params: queryParams,
    });
  };

  return (
    <div className="dashboard-container">
      <div className="section1">
        <div>
          <h3 className="main-title">Dashboard Analytics</h3>
        </div>
        {/* <div>
          <SelectBox
            menuList={["today", "tommorrow", "yesterday"]}
            label="Today"
            icon={CalendarIcon}
          />
        </div> */}
      </div>
      {allAnalytics?.length ? (
        <div className="section2">
          <ColorBox boxes={allAnalytics} />
        </div>
      ) : (
        <></>
      )}
      <div className="section3">
        <div>
          <h3 className="main-title">Recent Orders</h3>
        </div>
        <div className="section3-inputs">
          <SelectBox
            menuList={orderStatus}
            label="Filter"
            icon={FilterIcon}
            onSelect={changeStatus}
          />
          <TextField
            id="outlined-secondary"
            type="text"
            variant="outlined"
            size="small"
            className="input search-input"
            placeholder="Search"
            InputProps={{
              startAdornment: <img src={SearchIcon} className="search-icon" />,
            }}
            onChange={onSearch}
          />
        </div>
      </div>
      {allOrders.length ? (
        <Card plain>
          <CardBody>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {orderColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allOrders.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell>{row.table?.id || "N/A"}</TableCell>
                        <TableCell>{row.table?.name || "N/A"}</TableCell>
                        <TableCell>{row.customer?.Name || "-"}</TableCell>
                        <TableCell align="right">
                          {row.items.length + " items"}
                        </TableCell>
                        <TableCell align="right">{row.totalPrice}</TableCell>
                        <TableCell align="right">
                          {new Date(row.createdAt).toLocaleTimeString()}
                        </TableCell>
                        <TableCell align="right">
                          {PaymentMethodMapping[row.paymentMethod]}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            color: OrderStatusColorMapping[row.status],
                          }}
                        >
                          {OrderStatusMapping[row.status]}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={recentOrders.totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </CardBody>
        </Card>
      ) : (
        <div className="no-items-found">No Order Found</div>
      )}
    </div>
  );
}
Dashboard.propTypes = {
  getOrders: PropTypes.func.isRequired,
  getOrdersByStatus: PropTypes.func.isRequired,
  getAnalytics: PropTypes.func.isRequired,
  callInProcess: PropTypes.func.isRequired,
  recentOrders: PropTypes.any.isRequired,
  analytics: PropTypes.object.isRequired,
};
